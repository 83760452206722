import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@pages/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@pages/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import { PermissionsGuard } from '@guards/permissions.guard';
import {ForgotPasswordComponent} from '@pages/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@pages/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';

import {ContentManagerComponent} from '@pages/content-manager/content-manager.component';
import { PromoListComponent } from '@pages/promo-code/promo-list/promo-list.component';
import { SubscriptionsListComponent } from '@pages/subscriptions/subscriptions-list/subscriptions-list.component';
import { AccountTypesComponent } from '@modules/account-type/account-types/account-types.component';



const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: '/content-manager', 
                pathMatch: 'full' 
            },
            {
                path: 'profile',
                component: ProfileComponent,
            },
            {
                path: 'subscriptions',
                component:SubscriptionsListComponent
            },
            {
                path: 'promo-code',
                component: PromoListComponent
            },
            {
                path: 'content-manager',
                component: ContentManagerComponent
            },
            {
                path: 'account-manager',
                component: AccountTypesComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
        
            {
                path: 'account-types',
                loadChildren: () =>
                  import('./modules/account-type/account-type.module').then((mod) => mod.AccountTypeModule),
                //canActivate: [PermissionsGuard],
                data: { menu: 'account types'},
            },
            {
                path: 'access-group',
                loadChildren: () => import('./modules/access-control/access-control.module').then(m => m.AccessControlModule),
                canActivate: [PermissionsGuard],
                data: {
                    menu: 'access group'
                },
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
