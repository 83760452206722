import { Component, OnInit, Input } from '@angular/core';

declare var google: any;

@Component({
  selector: 'app-areachart',
  templateUrl: './areachart.component.html',
  styleUrls: ['./areachart.component.css']
})
export class AreachartComponent implements OnInit {

  private options;
  private data;
  private chart;

  @Input() idName;
  @Input() dataValue;
  @Input() type;
  @Input() rawColorArray;

  constructor() { }

  ngOnInit() {
    google.charts.load('current', {
      'packages': ['corechart']
    });
    google.charts.setOnLoadCallback(() => this.drawAreaChart());
  }

  /**
   * drawAreaChart
   */
  public drawAreaChart() {
    const rawData = [...this.dataValue];

    if (this.type == 'Company Performance') {
      this.data = new google.visualization.DataTable();
      this.data.addColumn('string', 'Year');
      this.data.addColumn('number', 'Sales');
      this.data.addColumn('number', 'Expenses');
      this.data.addRows(rawData);
    }

    if (this.type == 'Company Performance') {
      this.options = {
        title: 'Company Performance',
        hAxis: { title: 'Year', titleTextStyle: { color: '#333' } },
        vAxis: { minValue: 0 },
        chartArea: { top: 10, width: '70%' },
        legend: { position: 'bottom', alignment: 'center' },
        colors: ["#0072A7", "#5BCBFF"]
      };
    }

    this.chart = new google.visualization.AreaChart(document.getElementById(this.idName));
    this.chart.draw(this.data, this.options);
  }
}
