<pf-dropdown [hideArrow]="false" class="user-menu">
    <div slot="head">
        <a class="nav-link usrDropLink">
            <pf-image
                [src]="userData?.picture"
                fallbackSrc="assets/img/default-profile.png"
                class="user-image-small"
                width="30"
                height="30"
                alt="User Image"
                rounded="true"
            />
            <span>{{userData?.email}}<i class="topDrop fas fa-angle-down"></i></span>
        </a>
    </div>
    <div slot="body">
        <li class="user-header">
            <a [routerLink]="['/profile']" class="">
                <i class="fas fa-user-circle"></i>
                Profile
            </a>
        </li>
        <li class="user-header">
            <a (click)="logout()" class="">
                <i class="fas fa-sign-out-alt"></i>
                Sign out
            </a>
        </li>
    </div>
</pf-dropdown>


<a (click)="logout()" class="">
    <i class="fas fa-sign-out-alt"></i>
    Sign out
</a>