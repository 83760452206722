import { Component, Inject, Input, OnInit, TemplateRef, ViewChild, Output, EventEmitter, AfterViewInit, ElementRef, OnDestroy } from "@angular/core";
import { FormBuilder, FormControl, ReactiveFormsModule, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AlertService } from "@modules/shared/services/alert.service";
import { CommonService } from "@modules/shared/services/common.service";
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { LoaderService } from "@modules/shared/services/loader.service";
import { AccountTypesService } from "@modules/account-type/_services/account-types.service";
import { Config } from "datatables.net";

@Component({
  selector: 'app-access-control-list',
  templateUrl: './access-control-list.component.html',
  styleUrl: './access-control-list.component.scss'
})
export class AccessControlListComponent implements OnInit, OnDestroy {
  public accessControlList: any = [];
  public userData: any;

  /* DataTable */
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  /* dt Ends */

  public accountTypeForm!: FormGroup;

  public formType: string = 'Add';
  public errorMsg: string = '';
  public successMsg: string = '';

  constructor(
    private _router: Router,
    private _commonService: CommonService,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _accountTypeForm: FormBuilder,
    private _accountTypesService: AccountTypesService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this._loaderService.hideLoader();
    }, 100);

    this.userData = this._commonService.getItem('user_data');
    //this.userData = JSON.parse(this.userData);
    console.log(this.userData);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      paging: true,
      searching: true,
      processing: false
    };

    this.getAccessControlList();
  }

  /**
   * [getAccessControlList]
   */
  public getAccessControlList() {
    this._loaderService.showLoader();

    // this._accountTypesService.getAllAccessControlList().subscribe(
    //   (resp: any) => {
    //     if (resp.status) {
    //       this.accessControlList = [...resp.data];
    //       this.dtTrigger.next(1);
    //     } else {

    //     }
    //     this._loaderService.hideLoader();
    //   });

    let types = [
      {
        "id": 1,
        "name": "Super Admin",
        "user_management": "yes",
        "account_type_management": "yes",
        "acl_management": "yes"
      },
      {
        "id": 2,
        "name": "Admin",
        "user_management": "yes",
        "account_type_management": "yes",
        "acl_management": "yes"
      },
      {
        "id": 3,
        "name": "Regional Manager",
        "user_management": "yes",
        "account_type_management": "yes",
        "acl_management": "no"
      },
      {
        "id": 4,
        "name": "Branch Manager",
        "user_management": "yes",
        "account_type_management": "no",
        "acl_management": "no"
      },
      {
        "id": 5,
        "name": "User",
        "user_management": "no",
        "account_type_management": "no",
        "acl_management": "no"
      },
      {
        "id": 6,
        "name": "Developer",
        "user_management": "no",
        "account_type_management": "no",
        "acl_management": "no"
      }
    ];
    setTimeout(() => {
      this.accessControlList = [...types];
      this.dtTrigger.next(1);
      this._loaderService.hideLoader();
    }, 1000);
  }

  changeAccess(index, accessControlData, moduleName:string) {
    console.log(accessControlData);
    console.log(moduleName);
    if (accessControlData[moduleName] == "yes") {
      accessControlData[moduleName] = "no";
    } else {
      accessControlData[moduleName] = "yes";
    }
    this.accessControlList[index] = accessControlData;
    console.log(this.accessControlList);
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this._loaderService.showLoader();
      this.getAccessControlList();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
