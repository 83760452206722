import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private _toastr: ToastrService
  ) { }

  public showAlert(msg:any, type:any) {
    switch (type) {
      case 'success':
        if (Array.isArray(msg)) {
          msg.forEach(elm => {
            this._toastr.success(elm, "Success!");
          });
        } else {
          this._toastr.success(msg, 'Success!')
        }
        break;
      case 'error':
        if (Array.isArray(msg)) {
          msg.forEach(elm => {
            this._toastr.error(elm, "Error!");
          });
        } else {
          this._toastr.error(msg, "Error!");
        }
        break;
      case 'info':
        this._toastr.info(msg, 'Info')
        break;
      default:
        if (Array.isArray(msg)) {
          msg.forEach(elm => {
            this._toastr.info(elm, "Info");
          });
        } else {
          this._toastr.info(msg, "Info");
        }
        break;
    }
  }
}
