import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders , HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { APIConstant } from '@/constant/APIConstant';
@Injectable({
  providedIn: 'root'
})
export class ContentManagerService {
//	private APIEndpoint = environment.baseUrl;
  private APIEndpoint = environment.baseUrl;
  constructor(private http: HttpClient) { }

  public getAllGroup(){
	const headers = new HttpHeaders({
		'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
	  });
	return this.http.get(`${this.APIEndpoint+APIConstant.content.getGroupList}`, { headers });
}

	public getAllContentGroup(){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		return this.http.get(`${this.APIEndpoint+APIConstant.content.getContentGroupList}`, { headers });
	}
//	public getAllContent(){
	//	return this.http.get(`${this.APIEndpoint+APIConstant.content.getContentList}`);
//	}

	public addContent(params:any){
	//	console.log(this.getToken())
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
 		return this.http.post(`${this.APIEndpoint+APIConstant.content.addContent}`, params, { reportProgress:true, observe: 'events', headers});
	}

	public updateContent(params:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
	 	return this.http.post(`${this.APIEndpoint+APIConstant.content.editContent}`, params,  {  reportProgress:true, observe: 'events', headers });
	}

	public deleteContent(params:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
 return this.http.delete(`${this.APIEndpoint+APIConstant.content.deleteContent+params}`, { headers });
	}	

	getToken(){
		return localStorage.getItem('access_token');
	}
  
}
