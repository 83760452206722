import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Components */
import { LoaderComponent } from './loader/loader.component';
import { ColumnChartComponent } from './google-charts/column-chart/column-chart.component';
import { BarChartComponent } from './google-charts/bar-chart/bar-chart.component';
import { PiechartComponent } from './google-charts/piechart/piechart.component';
import { LineChartComponent } from './google-charts/line-chart/line-chart.component';
import { AreachartComponent } from './google-charts/areachart/areachart.component';

@NgModule({
  declarations: [
    LoaderComponent,
    ColumnChartComponent,
    BarChartComponent,
    PiechartComponent,
    LineChartComponent,
    AreachartComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports:[
    ReactiveFormsModule,
    FormsModule,
    LoaderComponent,
    ColumnChartComponent,
    BarChartComponent,
    PiechartComponent,
    LineChartComponent,
    AreachartComponent
  ],
  providers:[
  ]
})
export class SharedModule { }
