import { Injectable, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, delay, EMPTY, Observable, Subscription, tap, throwError, of } from 'rxjs';
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
// import { environment } from 'src/environments/environment';
// import Swal from 'sweetalert2';

import { AlertService } from "./alert.service";
import { LoaderService } from './loader.service';

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private _authservice: AuthService,
    private commonService: CommonService,
    private http: HttpClient,
    private router: Router,
    private _alertService: AlertService,
    //private _loaderService: LoaderService,
  ) { }

  timer: any;
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let req;
    //console.log('====', request, next, window.location.href, request.url);
   
    if (request.url) {
      req = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.commonService.getItem('access_token')}`,
        }
      });
    } else {
      req = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.commonService.getItem('access_token')}`,
          'Content-Type': 'application/json'
        }
      });
    }

    return next.handle(req).pipe(catchError(error => {
      //console.log('error',error, error instanceof HttpErrorResponse, error.status);
      if ((error.status === 0 || error.status === 401 || error.status === 400 || error.status === 404 || error.status === 422 || error.status === 500)) {
        // A client-side or network error occurred. Handle it accordingly.
        if (error.status == 0) {
        //  this._alertService.showAlert(error.statusText, 'error');
        } else if (error.status == 401) {
          // unAutharized user!!
       //   this._alertService.showAlert(error.error.message, 'error');
          this.commonService.removeAll();
          this.router.navigate(['login']);
        } else if (error.status == 400) {
          // missing params
        //  this._alertService.showAlert(error.error.message, 'error');
        } else if (error.status == 404) {
          // missing params
        //  this._alertService.showAlert('Invalid Api Url', 'error');
        } else {
          console.log(error.statusText);
        //  this._alertService.showAlert(error.statusText, 'error');
        }
      }

      return throwError(error);
    }));
  }
}
