import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'environments/environment';
import { APIConstant } from '@/constant/APIConstant';

@Injectable({
	providedIn: 'root'
})
export class RegistrationService {
	constructor(
		private http: HttpClient,
	) {}

	public userRegistration(params:any) {
	//	return this.http.post(`${environment.baseUrl+APIConstant.auth.registration}`, params);
	}
}