import { Component, OnInit, TemplateRef, ViewChild, OnDestroy, Renderer2, ElementRef } from "@angular/core";
import { AlertService } from "@modules/shared/services/alert.service";
import { CommonService } from "@modules/shared/services/common.service";
import { LoaderService } from "@modules/shared/services/loader.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ContentManagerService } from "./_services/content-manager.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";


@Component({
  selector: 'app-content-manager',
  templateUrl: './content-manager.component.html',
  styleUrl: './content-manager.component.scss'
})
export class ContentManagerComponent {
  @ViewChild('fileText') fileText: any;
  @ViewChild('fileInput') fileInput: any;
  @ViewChild('file') file: any;
  @ViewChild('editVideoModalClose') editVideoModalClose!: ElementRef;
  @ViewChild('addVideoModalClose') addVideoModalClose!: ElementRef;
  public contentGroupList: any = [];
  maxVideoFileSize: number = 200; //max Video File Size in Mb
  fileErrorMessage: boolean = false;
  editfileErrorMessage: boolean = false;
  videoForm: FormGroup;
  editvideoForm: FormGroup;
  group_name: any;
  group_id: any;
  videoId: any;
  uploadedVideoUrl: string;
  videoFile: File | null = null;
  videoFileName: string | null = null;
  editVideoFileName: string | null = null;
  videoUrl: any;
  progress: number = null;
  groupList:any;

  constructor(
    private _commonService: CommonService,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _contentService: ContentManagerService,
    private modalService: BsModalService,
    private _fb: FormBuilder,
    renderer: Renderer2
  ) {

    this.videoForm = this._fb.group({
      heading: ['', [Validators.required, Validators.maxLength(50)]],
      sub_heading: ['', [Validators.required, Validators.maxLength(100)]],
      content_group_id: '',
      file: [null, Validators.required]
    });
    this.editvideoForm = this._fb.group({
      heading: ['', [Validators.required, Validators.maxLength(50)]],
      sub_heading: ['', [Validators.required, Validators.maxLength(100)]],

      file: [null, Validators.required]
    });
  }
  simulateButtonClick() {
    // Trigger button click programmatically
    this.editVideoModalClose.nativeElement.click();
  }
  ngOnInit() {
    this.getContentGroupList();
    this.getGroupList();

    
  }
  getGroupList(){
    this._loaderService.showLoader();
    this._contentService.getAllGroup().subscribe({
      next: (resp: any) => {
        console.log(resp);
        console.log(resp.data);
        if (resp) {
        //  this.contentGropuList = [...resp.data];
          this.groupList = [...resp.data];

 
          
         // this.contentGroupList = this.contentGroupList.sort((a, b) =>a.content.id -  b.content.id );
          
          //  console.log(this.contentGropuList);
          this._loaderService.hideLoader();
        } else {
          this._loaderService.hideLoader();
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        console.error(error);
      }
    });

  }
  getContentGroupList() {
    this._loaderService.showLoader();
    this._contentService.getAllContentGroup().subscribe({
      next: (resp: any) => {
        console.log(resp);
        console.log(resp.data);
        if (resp) {
        //  this.contentGropuList = [...resp.data];
          this.contentGroupList = [...resp.data];
 
          console.log(this.contentGroupList);
          
          this.contentGroupList.forEach(item => {
            item.content.sort((a, b) => b.id - a.id);
          });
          
         // this.contentGroupList = this.contentGroupList.sort((a, b) =>a.content.id -  b.content.id );
          
          //  console.log(this.contentGropuList);
          this._loaderService.hideLoader();
        } else {
          this._loaderService.hideLoader();
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        console.error(error);
      }
    });

  }

  addVideoModal(item: any) {
    this.progress=null;
    // this.videoFileName = this.videoForm.value.file.name ;
    this.videoForm.value.file = '';
    // console.log("------------")
    this.videoFileName = ''
    //this.file.nativeElement.value = '';
    // console.log(this.videoForm.value.file)
    console.log(item.group_name)
    this.group_name = item.group_name;
    this.group_id = item.id;
    console.log(this.videoForm)
    this.videoForm = this._fb.group({
      heading: ['', [Validators.required, Validators.maxLength(50)]],
      sub_heading: ['', [Validators.required, Validators.maxLength(100)]],
      file: ['', Validators.required]
    });


  }
  editVideoModal(item: any, itemcat: any) {
    this.progress=null;
    console.log(item.id);
    console.log(item);
  //  this.fileInput.nativeElement.value = '';
    this.videoId = item.id
    this.videoUrl = item.thumbnail
    this.group_name = itemcat;
    this.editVideoFileName = item.file;
    // this.group_id= item.content_group_id;
    this.editvideoForm = this._fb.group({
      heading: [item.heading, [Validators.required, Validators.maxLength(50)]],
      sub_heading: [item.sub_heading, [Validators.required, Validators.maxLength(100)]],
      file: [item.file, Validators.required]
    });
  }

  fileUploadFakeLink() {

    this.fileInput.nativeElement.click();
  }

  addVideo(group_id) {


    const formData = new FormData();
    formData.append('heading', this.videoForm.get('heading').value);
    formData.append('sub_heading', this.videoForm.get('sub_heading').value);
    formData.append('content_group_id', group_id);
    formData.append('file', this.videoForm.value.file);
    console.log("new video");
    console.log(this.videoForm.value.file);
    if (this.videoForm.valid) {
      this.submitNewVideo(formData);
    }
    else {
      this._alertService.showAlert("Invalid Form", 'error');
    }


  }
  editVideo(id) {
    const formData = new FormData();
    formData.append('content_id', id);
    formData.append('heading', this.editvideoForm.get('heading').value);
    formData.append('sub_heading', this.editvideoForm.get('sub_heading').value);
    formData.append('file', this.editvideoForm.value.file);
    console.log("video Edit");
    console.log(this.editvideoForm.value.file);
    // console.log(this.editvideoForm.value.fileEdit)
    if (this.editvideoForm.valid) {
      this.submitEditVideo(formData);
    }
    else {
      this._alertService.showAlert("Invalid Form", 'error');
    }
  }
  submitNewVideo(formData: FormData) {
    this.progress=0;
    const myModalEl = document.getElementById('addVideoModalClose');
   
   //this._loaderService.showLoader();

    this._contentService.addContent(formData).subscribe({
      next: (resp: any) => { 
   
        
        if (resp.type === HttpEventType.UploadProgress){
        
          this.progress = Math.round(100 * resp.loaded / resp.total);
          console.log(`Uploaded! ${this.progress}%`);
       //  myModalEl.click();
        //  const modal: any = this.progressBarModal.nativeElement;
        //   modal.style.display = 'block';
       //  modal.classList.add('show');
        }
        else if (resp instanceof HttpResponse) {
          // Upload completed successfully
          console.log('File uploaded successfully!', resp.body);
       //   this._loaderService.hideLoader();
       this.addVideoModalClose.nativeElement.click();
                
     
          this._alertService.showAlert("File uploaded successfully!", 'success');
          this.rerender();
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();

        console.error(error);
      }
    });
  }
  submitEditVideo(formData: FormData) {
    this.progress=1;
    const jsonData = this.formDataToJson(formData);

   // this._loaderService.showLoader();

    this._contentService.updateContent(formData).subscribe({
      next: (resp: any) => {



   
        
        if (resp.type === HttpEventType.UploadProgress){
        
          this.progress = Math.round(100 * resp.loaded / resp.total);
          console.log(`Uploaded! ${this.progress}%`);
         
       //  myModalE2.click();
        //  const modal: any = this.progressBarModal.nativeElement;
        //   modal.style.display = 'block';
       //  modal.classList.add('show');
        }
        else if (resp instanceof HttpResponse) {
          // Upload completed successfully
          console.log('File update successfully!', resp.body);
       //   this._loaderService.hideLoader();
       this.editVideoModalClose.nativeElement.click();
          this._alertService.showAlert("File update successfully!", 'success');
          this.rerender();
        }
 
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        this.editVideoModalClose.nativeElement.click();
        console.error("errora");
        console.error(error.statusText);
        this._alertService.showAlert(error.statusText, 'error');
      }
    });
  }
  onFileChange(event) {

    this.fileErrorMessage = false;
    console.log('size', event.target.files[0].size);
    console.log('Name', event.target.files[0].name);
    this.videoFileName = event.target.files[0].name;

    const videofileSize = this.bytesToMB(event.target.files[0].size);
    if (videofileSize < this.maxVideoFileSize) {

      if (event.target.files.length > 0) {
     //   console.log("a1");
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
       //     console.log("a2");
          };
          reader.readAsDataURL(file);
       //   console.log("a3");
        }
        this.videoForm.patchValue({
          file: file
        });
       // console.log("a4");

      }
    }
    else {
      this.fileErrorMessage = true;
    }

  }

  onFileChangeEdit(event) {
    this.videoUrl = '';
    this.editfileErrorMessage = false;
    console.log('size', event.target.files[0].size);
    console.log('Name', event.target.files[0].name);
    this.editVideoFileName = event.target.files[0].name;
    // this.videoFileName = event.target.files[0].name;
    const videofileSize = this.bytesToMB(event.target.files[0].size);
    if (videofileSize < this.maxVideoFileSize) {

      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e: any) => {

          };
          reader.readAsDataURL(file);
        }

        this.editvideoForm.patchValue({
          file: file
        });


        console.log(file)
      }
    }
    else {
      this.editfileErrorMessage = true;
    }

  }


  public editContent(params) {
    this._contentService.updateContent(params).subscribe({
      next: (resp: any) => {
        console.log(resp);
        if (resp) {

          this.rerender();
          this._loaderService.hideLoader();
          this._alertService.showAlert('Content Details Updated Sucessfully', 'success');
        } else {
          this._loaderService.hideLoader();
          this._alertService.showAlert(resp.message, 'error');
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        console.error(error);
      }
    });
  }

  confirmContentDeleteBox(id) {

    console.log(id)

    Swal.fire({
      title: 'Delete Video',
      text: 'Are you sure you want to delete this video? This process is irreversible and all video data will be lost.',
      // icon: 'warning',
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Delete Video',
      // cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this._contentService.deleteContent(id).subscribe(
          (resp: any) => {
            console.log(resp);
            this.rerender();
            this._alertService.showAlert(resp.message, 'success');

          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {

      }
    })

  }
  /******* render data */
  rerender() {

    // Destroy the table first

    this.getContentGroupList();

  }



  bytesToMB(bytes: number): number {
    return bytes / (1024 * 1024);
  }

  formDataToJson(formData: FormData): any {
    let jsonObject: any = {};
    formData.forEach((value, key) => {
      jsonObject[key] = value;
    });
    return jsonObject;
  }
 
}
