<div class="modal-header">
    <h5 class="modal-title pull-left">{{modalType}} Account Type</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form autocomplete="false" name="accountTypeForm" method="post" id="accountTypeForm" [formGroup]="accountTypeForm">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="account-type-name" class="col-form-label">Account Type Name
                        <span class="required-fields">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="account_type_name">
                    <div class="alert alert-danger"
                        *ngIf="accountTypeForm.get('account_type_name')?.invalid && accountTypeForm.get('account_type_name')?.errors!=null && (accountTypeForm.get('account_type_name')?.dirty || accountTypeForm.get('account_type_name')?.touched)">
                        <div *ngIf="accountTypeForm.get('account_type_name')?.hasError('required')">
                            This field is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submitAddAccountTypeForm()"
        [disabled]="accountTypeForm.invalid" *ngIf="modalType=='Add'">Save</button>
    <button type="button" class="btn btn-primary" (click)="submitEditAccountTypeForm()"
        [disabled]="accountTypeForm.invalid" *ngIf="modalType=='Edit'">Update</button>
</div>
