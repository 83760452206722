import { Injectable } from '@angular/core';
// import { AuthJwtService } from './auth-jwt.service';
import { HttpClient } from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt'; // token decoder module

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private http: HttpClient,) {
  }
  jwtHelper: JwtHelperService = new JwtHelperService(); // create jwt helper library

  setItem(keyName: string, keyValue: string)
  {
    if (keyName == 'user_data') {
      keyValue = JSON.stringify(keyValue);
    }

    localStorage.setItem(keyName, keyValue);
  }

  getItem(keyName: string)
  {
    //let storedData: any;
    let keyValue = localStorage.getItem(keyName);
    // if (keyName == 'access_token' && keyValue != null) {
    //   storedData = keyValue!.replace('Bearer ', '');
    // } else if (keyName != 'access_token')  {
    //   storedData = localStorage.getItem(keyName);
    // }
    if (keyValue) {
        //if (keyName == 'access_token') {
          //keyValue = this.jwtHelper.decodeToken(keyValue)
        //}

        if (keyName == 'user_data') {
          keyValue = JSON.parse(keyValue);
        }

        return keyValue;
    } else {
      return null;
    }
  }

  removeItem(keyName: string) {
    localStorage.removeItem(keyName);
  }

  removeAll() {
    localStorage.clear();
  }
}
