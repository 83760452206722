import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import { AuthService } from '@services/auth.service';
import {DateTime} from 'luxon';
import { CommonService } from '@modules/shared/services/common.service';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
    public userData;

    constructor(private appService: AppService, 
        private authservice: AuthService,
        private commonService: CommonService) {}

    ngOnInit(): void {
        //this.userData = this.appService.user;
        this.userData = this.commonService.getItem('user_data');
        //console.log(this.userData);
    }

    logout() {
        //this.appService.logout();
       // this.authservice.signOut(); 
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd LLL yyyy');
    }
}
