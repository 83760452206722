import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding
} from '@angular/core';
import { Router } from '@angular/router';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { AlertService } from '@modules/shared/services/alert.service';
import { LoaderService } from '@modules/shared/services/loader.service';
import { AuthService } from '@services/auth.service';
import { CommonService } from '@modules/shared/services/common.service';
import { AuthJwtService } from '@modules/shared/services/auth-jwt.service';

import { SocialAuthService } from "@abacritt/angularx-social-login";
import { SocialUser } from "@abacritt/angularx-social-login";
import { JsonData } from '@/constant/JsonData';
import {
    GoogleLoginProvider,
    FacebookLoginProvider,
    GoogleSigninButtonDirective,
    GoogleSigninButtonModule
  } from '@abacritt/angularx-social-login';

  declare var google: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'container-fluid';
    public loginForm: FormGroup;    
    public isRememberMeOn:any = false;
    public emailId: string = '';
    public password: string = '';
    code: string = '';
    errorLoginCredential: string = ''
    user: SocialUser;
    loggedIn: boolean;

    constructor(
        private _router: Router,
        private renderer: Renderer2,
        private _authservice: AuthService,
        private commonService: CommonService,
        private _alertService: AlertService,
        private _loaderService: LoaderService,
        private authJwtService: AuthJwtService,
        private socialAuthService: SocialAuthService,
    ) {}

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        
        if (this.commonService.getItem('is_remember_me') !== null) {
            this.isRememberMeOn = this.commonService.getItem('is_remember_me');
            this.emailId = this.commonService.getItem('emailId');
            this.password = this.commonService.getItem('password');
        }

        this.loginForm = new FormGroup({
            email: new FormControl(this.emailId, [Validators.required, Validators.email]),
            password: new FormControl(this.password, [Validators.required])
        });

        this.socialAuthService.authState.subscribe((user) => {
            this.user = user;
            this.loggedIn = (user != null);
          }); 
    }

    ngAfterViewInit() {
        google.accounts.id.initialize({
            client_id: "58323982186-vtmmbscrd9bs79kkg59b6umuiiu571ve.apps.googleusercontent.com",
            callback: (resp:any) => {
                this.handleGoogleLogin(resp);
            }
        });

        google.accounts.id.renderButton(
            document.getElementById("google-btn"),
            { theme: "filled_blue", size: "large", shape: "rectangular", width:"320"  }  // customization attributes
        );
    }

    loginByGoogle(): void {
        //this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    handleGoogleLogin(resp: any) {
        if (resp) {
            let tokenData = this.authJwtService.decodeAuthToken(resp.credential);
            console.log(tokenData);
            this.commonService.setItem('access_token', resp.credential);
            let userData:any = {
                email: tokenData.email,
                name: tokenData?.name,
                picture: tokenData?.picture
            }
            this.commonService.setItem('user_data', userData);
            this._alertService.showAlert('Login Successfully Done', 'success');
            setTimeout(() => {
                this._router.navigate(['/']);
            }, 500);
        }
    }

    loginByFacebook(): void {
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

    signIn() {
        if (this.loginForm.valid) {
            this._loaderService.showLoader();
            console.log(this.loginForm.value.email)
            console.log(this.loginForm.value.password)
            const perms = {
                email:this.loginForm.value.email,
                password:this.loginForm.value.password
            }
            console.log("111");
            console.log(perms);
            console.log("aaa");
            this._authservice.signIn(perms).subscribe(
            (res: any) => {
                console.log(res);
                
                if (this.isRememberMeOn) {
                    this.commonService.setItem('emailId', this.loginForm.value.email);
                    this.commonService.setItem('password', this.loginForm.value.password);
                } else {
                    this.commonService.removeItem('emailId');
                    this.commonService.removeItem('password');
                }
    
                this.loginCallback(res);
            },
            err => {
              
                this.loginErrCallback(err);
            }
          );
        } else {
          console.log(this.loginForm);
        }
    }
    
    loginCallback(res:any) {
        if (res.status=='success') {
            this.setLocalstorage(res.data);
            //this.getUserAccessList(res.data);
            setTimeout(() => {
                this._alertService.showAlert(res.message, 'success');
                this._router.navigate(['/content-manager']);
                this._loaderService.hideLoader();
            }, 500);
        } else {
            this._loaderService.hideLoader();
        
          //  this._alertService.showAlert(res.message, 'error');
        }
    }

    loginErrCallback(err:any) {
        this._loaderService.hideLoader();
     //console.log(err.error.message, "asdasd");
     this.errorLoginCredential = err.error.message;
     //  this._alertService.showAlert(err.message, 'error');
      //  console.log("Login Error =>" + err.message);
    }

    makeBody()
    {
        const body = {
            email: this.loginForm.value.email,
            password: this.loginForm.value.password
        };
        return body;
    }

    setLocalstorage(res)
    {
        //this.commonService.setItem('access_token', res.access_token);
        this.commonService.setItem('access_token', res.data.AccessToken);

        //this.commonService.setItem('token_expiry', res.data.RefreshToken);
      //  let userData:any = {
        //    id: res.id,
         //   email: res.email,
         //   name: res?.name,
         //   picture: res?.picture
       // }
       // this.commonService.setItem('access_data', JSON.stringify(JsonData.user.userAccess));
      //  this.commonService.setItem('user_access', JSON.stringify(JsonData.user.userAccess));
    }

    public remenberMe(value) {
        if (value) {
            this.commonService.setItem('is_remember_me', value);
        } else {
            this.commonService.removeItem('is_remember_me');
        }
    }

    getUserAccessList(res) {
        let param = {
            "email": res.email
        }
        this._authservice.getAccessList(param).subscribe({
            next: async (res: any) => {
                if(res.status) {
                    localStorage.setItem('user_access', JSON.stringify(res.data));
                    //await this._router.navigateByUrl('/');
                } else {
                    this._loaderService.hideLoader();
                  //  this._alertService.showAlert(res.message, 'error');
                }
            },
            error: (error:any) => {
                this._loaderService.hideLoader();
               // this._alertService.showAlert(res.message, 'error');
            }
        });
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
