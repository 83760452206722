// csv.service.ts

import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CsvService {

  constructor() { }

  downloadCsv(data: any[], filename: string) {
    const dataWithSerialNumbers = this.addSerialNumbers(data);
    const csvData = this.convertToCsv(dataWithSerialNumbers);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, filename);
  }
  private addSerialNumbers(data: any[]): any[] {
    return data.map((item, index) => ({
      serialNo: index + 1,
      ...item
    }));
  }
  private convertToCsv(data: any[]): string {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map(row => Object.values(row).join(','));
    return `${header}\n${rows.join('\n')}`;
  }
}