<div (mouseenter)="mouseEnterOnMenuSidebar()" (mouseleave)="mouseLeaveFromMenuSidebar()">
    <!-- Brand Logo -->
    <!-- <a [routerLink]="['/']" class="brand-link border-0">
    <ng-container *ngIf="!showMiniLogoIcon">
        <img src="../../../../assets/img/logo-main.png" width="100%">
    </ng-container>
    <ng-container *ngIf="showMiniLogoIcon">
        <img src="../../../../assets/img/logo-main.png" width="100%" *ngIf="onMouseOver">
        <img src="../../../../assets/img/logo-icon.png" height="45px" *ngIf="!onMouseOver">
    </ng-container>
</a> -->

    <!-- Sidebar -->
    <div class="sidebar">
        <div class="form-inline">
            <app-sidebar-search></app-sidebar-search>
        </div>

        <!-- Sidebar Menu -->
        <div class="sideHeader"></div>
        <div class="sideNav">
            <nav class="">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <!-- <ng-container *ngFor="let item of menu">
                <app-menu-item  [menuItem]="item"/>
            </ng-container> -->

                 <!-- <li>
                        <a routerLink="/subscriptions" class="">
                            <img src="../../../../assets/img/Subscriptions.png" alt="icon">
                            Subscriptions
                        </a>
                    </li>
-->  
                    <li>
                        <a routerLink="/promo-code" class="">
                            <img src="../../../../assets/img/promo.png" alt="icon">
                            Promo Codes
                        </a>
                    </li>


                    <li>
                        <a routerLink="/content-manager" class="">
                            <img src="../../../../assets/img/conntent.png" alt="icon">
                            Content Manager
                        </a>
                    </li>

                   
                </ul>



                <div class="signOut">
                    <a (click)="logout()" class="">
                        <img src="../../../../assets/img/signOut.png" alt="">
                        Logout
                    </a>
                </div>
            </nav>
        </div>
    </div>
</div>