<div class="content-header">
  <div class="container-fluid">
    <div class="headingTop">
      <div class="row mb-2 align-items-center">
        <div class="col-sm-6 headingCol">
          <h1 class="m-0 text-dark">Dashbdfdoard </h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6 headingCol">
          <!-- <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard v1</li>
                </ol> -->
        </div>
        <!-- /.col -->
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>
<!-- /.content-header -->

<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <!-- Small boxes (Stat box) -->
    <div class="row">
      <!-- ./col -->
      <div class="col-xl-3 col-6 dashTopCol">
        <!-- small box -->
        <div class="small-box bg-info">
          <div class="cardcont">
            <div class="icnBlock row">
              <div class="col-3 pr-0">
                <div class="icnBox icon-box3 text-center"><img src="assets/img/totalAdmin.png"></div>
              </div>
              <div class="col-9 icon-content">
                <h5>197</h5>
                <p>Total Admin</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-xl-3 col-6 dashTopCol">
        <!-- small box -->
        <div class="small-box bg-info">
          <div class="cardcont">
            <div class="icnBlock row">
              <div class="col-3 pr-0">
                <div class="icnBox icon-box4 text-center"><img src="assets/img/totalApprove.png"></div>
              </div>
              <div class="col-9 icon-content">
                <h5>110</h5>
                <p>Total Approves</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./col -->

      <div class="col-xl-3 col-6 dashTopCol">
        <!-- small box -->
        <div class="small-box bg-info">
          <div class="cardcont">
            <div class="icnBlock row">
              <div class="col-3 pr-0">
                <div class="icnBox icon-box1 text-center"><img src="assets/img/ico2.png"></div>
              </div>
              <div class="col-9 icon-content">
                <h5>115</h5>
                <p>Total Customers</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ./col -->
      <div class="col-xl-3 col-6 dashTopCol">
        <!-- small box -->
        <div class="small-box bg-info">
          <div class="cardcont">
            <div class="icnBlock row">
              <div class="col-3 pr-0">
                <div class="icnBox icon-box2 text-center"><img src="assets/img/ico1.png"></div>
              </div>
              <div class="col-9 icon-content">
                <h5>110</h5>
                <p>Active Customers </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- /.row -->
    <div class="dashMidDv1">
      <div class="row mt-4">
        <div class="col-md-12">
          <h5 class="mb-3 heading-font">Search & Filter</h5>
        </div>
      </div>
      <div class="row ">
        <div class="col-lg-3 col-md-3 col-6 frmGrp">
          <div class="datepicker date input-group frm-select2">
            <input type="text" placeholder="Start date" bsdatepicker=""
              class="form-control height-eq ng-untouched ng-pristine ng-valid">
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6 frmGrp">
          <div class="datepicker date input-group frm-select2">
            <input type="text" placeholder="End date" bsdatepicker=""
              class="form-control height-eq ng-untouched ng-pristine ng-valid">
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6 frmGrp">
          <div class="datepicker date input-group frm-select2">
            <input type="text" placeholder="Search" class="form-control height-eq ng-untouched ng-pristine ng-valid">
          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-6 frmGrp  btn-part srchFrmBtn">
          <button class="btn btn-primary w-100">Search</button>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-4 col-md-6 account">
          <div class="card" style="height: 330px;">
            <div class="card-title text-center card-title"> Accounts </div>
            <div class="card-body">
              <div class="row text-center">
                <div class="col-md-6"><img src="assets/img/sold.png">
                  <div class="card-value">0</div><small class="card-value-sub-title"><b>Sold</b></small>
                </div>
                <div class="col-md-6"><img src="assets/img/services.png">
                  <div class="card-value">0</div><small class="card-value-sub-title"><b>Serviced</b></small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 revenue">
          <div class="card revenue-card">
            <div class="card-title text-center card-title"> Revenue </div>
            <div class="row text-center mb-3">
              <div class="col-md-6">
                <div class="card-value-3">$0.00</div><small class="card-value-sub-title"><b>Sold</b></small>
              </div>
              <div class="col-md-6">
                <div class="card-value-3">$0.00</div><small class="card-value-sub-title"><b>Serviced</b></small>
              </div>
            </div>
          </div>
          <div tooltip="View Details" class="card" style="height: 155px; margin-top: 20px;">
            <div class="card-title text-center card-title"> 15 Days Past Due </div>
            <div class="row text-center mb-3">
              <div class="col-md-12">
                <div class="card-value-2">0.00%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 average-contract">
          <div class="card" style="height: 155px;">
            <div class="card-title text-center card-title"> Average Contract Value </div>
            <div class="row text-center mb-3" style="margin-top: 5px;">
              <div class="col-md-12">
                <div class="card-value-2">$0.00</div>
              </div>
            </div>
          </div>
          <div class="card" style="height: 155px; margin-top: 20px;">
            <div class="card-title text-center card-title"> Average Initial Service Price </div>
            <div class="row text-center mb-3">
              <div class="col-md-12">
                <div class="card-value-2">$0.00</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-4 col-md-6">
          <div class="card revenue-break-down">
            <div class="card-title text-center card-title"> Sold Revenue Breakdown </div>
            <div class="card-body">
              <div _ngcontent-mun-c130="" class="row">
                <div _ngcontent-mun-c130="" class="col-md-12 text-center"><img _ngcontent-mun-c130=""
                    src="assets/img/not-found.jpg" class="no-data-image">
                  <div _ngcontent-mun-c130="" class="no-data-div">
                    <div _ngcontent-mun-c130="" class="no-data-header">Data not found</div>
                    <div _ngcontent-mun-c130="" class="no-data-sub-title">There are no conversations to show here
                      right now.</div>
                  </div>
                </div>
              </div><!----><!---->
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 service-revenu-row">
          <div class="card service-revenu-card">
            <div class="card-title text-center card-title"> Service Revenue Breakdown </div>
            <div class="card-body">
              <div _ngcontent-mun-c130="" class="row">
                <div _ngcontent-mun-c130="" class="col-md-12 text-center"><img _ngcontent-mun-c130=""
                    src="assets/img/not-found.jpg" class="no-data-image">
                  <div _ngcontent-mun-c130="" class="no-data-div">
                    <div _ngcontent-mun-c130="" class="no-data-header">Data not found</div>
                    <div _ngcontent-mun-c130="" class="no-data-sub-title">There are no conversations to show here
                      right now.</div>
                  </div>
                </div>
              </div><!----><!---->
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 knock-row">
          <div class="card" style="height: 300px;">
            <div class="card-title text-center card-title"> Total Knocks </div>
            <div class="card-body mb-4">
              <div class="row text-center">
                <div class="col-md-12"><img src="assets/img/koncks.png">
                  <div class="card-value mt-5"><small style="font-size: 12px; color: gray;">Data not available</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-3 col-md-6">
          <div class="card" style="height: 300px;">
            <div class="card-title text-center card-title"> Not Serviced </div>
            <div class="card-body mb-3">
              <div class="row text-center">
                <div class="col-md-12"><img src="assets/img/not-serviced.png" style="padding: 6px;">
                  <div class="card-value mt-4">0.00%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 dicision-marker-row">
          <div class="card" style="height: 300px;">
            <div class="card-title text-center card-title"> Total Decision Makers </div>
            <div class="card-body mb-3">
              <div class="row text-center">
                <div class="col-md-12"><img src="assets/img/decision.png" style="padding: 3px;">
                  <div class="card-value mt-5"><small style="font-size: 12px; color: gray;">Data not available</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 cancellation-row">
          <div class="card cancellation-card">
            <div class="card-title text-center card-title"> Cancellations </div>
            <div class="card-body mb-3">
              <div _ngcontent-mun-c131="" class="row">
                <div _ngcontent-mun-c131="" class="col-md-12 text-center"><img _ngcontent-mun-c131=""
                    src="assets/img/not-found.jpg" class="no-data-image">
                  <div _ngcontent-mun-c131="" class="no-data-div">
                    <div _ngcontent-mun-c131="" class="no-data-header">Data not found</div>
                    <div _ngcontent-mun-c131="" class="no-data-sub-title">There are no conversations to show here
                      right now.</div>
                  </div>
                </div>
              </div><!----><!---->
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <h5 class="mb-3 heading-font">Google Charts</h5>
        </div>
      </div>
      <hr>
      <div class="row mt-4">
        <div class="col-lg-6 col-md-6">
          <div class="card pay payment-card">
            <div class="card-title text-center card-title"> Bar Chart</div>
            <div class="card-body">
              <app-bar-chart [idName]="'bar-chart'" [dataValue]='barChartData' [type]="'population'">
              </app-bar-chart>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="card pay payment-card">
            <div class="card-title text-center card-title"> Column Chart</div>
            <div class="card-body">
              <app-column-chart [idName]="'column-chart'" [dataValue]='columnChartData' [type]="'population'">
              </app-column-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-4 col-md-6">
          <div class="card pay payment-card">
            <div class="card-title text-center card-title"> Pie Chart</div>
            <div class="card-body">
              <app-piechart [idName]="'pie-chart'" [dataValue]='pieChartData' [type]="'population'">
              </app-piechart>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card pay payment-card">
            <div class="card-title text-center card-title"> Pie Chart</div>
            <div class="card-body">
              <app-piechart [idName]="'browser'" [dataValue]='browserData' [type]="'browser'">
              </app-piechart>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="card pay payment-card">
            <div class="card-title text-center card-title"> Pie Chart</div>
            <div class="card-body">
              <app-piechart [idName]="'language'" [dataValue]='languageData' [type]="'language'">
              </app-piechart>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="card pay payment-card">
            <div class="card-title text-center card-title"> Line Chart</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 text-center">
                  <app-line-chart [idName]="'company_performance'" [dataValue]='companyPerformanceData'
                    [type]="'Company Performance'">
                  </app-line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="card pay payment-card">
            <div class="card-title text-center card-title"> Line Chart</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 text-center">
                  <app-line-chart [idName]="'BoxOfficeEarnings'" [dataValue]='boxOfficeEarningsData'
                    [type]="'BoxOfficeEarnings'">
                  </app-line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-6">
          <div class="card pay payment-card">
            <div class="card-title text-center card-title"> Area Chart</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 text-center">
                  <app-areachart [idName]="'area-chart'" [dataValue]='companyPerformanceData'
                    [type]="'Company Performance'"></app-areachart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-12">
          <h5 class="mb-3 heading-font">Apex Charts</h5>
        </div>
      </div>
      <hr>
      <div class="row mt-4">
        <div class="col-lg-6 col-md-6">
          <div class="card pay apex-chart-card">
            <div class="card-title text-center card-title"> Bar Chart</div>
            <div class="card-body">
              <apx-chart [series]="basicApexBarChartOptions.series" [colors]="basicApexBarChartOptions.colors"
                [chart]="basicApexBarChartOptions.chart" [dataLabels]="basicApexBarChartOptions.dataLabels"
                [plotOptions]="basicApexBarChartOptions.plotOptions"
                [xaxis]="basicApexBarChartOptions.xaxis"></apx-chart>
              <!-- <div id="apex-bar-chart"></div> -->
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="card pay apex-chart-card">
            <div class="card-title text-center card-title"> Column Chart</div>
            <div class="card-body">
              <apx-chart [series]="basicApexColumnChartOptions.series" [chart]="basicApexColumnChartOptions.chart"
                [colors]="basicApexColumnChartOptions.colors" [dataLabels]="basicApexColumnChartOptions.dataLabels"
                [plotOptions]="basicApexColumnChartOptions.plotOptions" [yaxis]="basicApexColumnChartOptions.yaxis"
                [legend]="basicApexColumnChartOptions.legend" [fill]="basicApexColumnChartOptions.fill"
                [stroke]="basicApexColumnChartOptions.stroke" [tooltip]="basicApexColumnChartOptions.tooltip"
                [xaxis]="basicApexColumnChartOptions.xaxis"></apx-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-6 col-md-6">
          <div class="card pay apex-chart-card">
            <div class="card-title text-center card-title"> Pie Chart</div>
            <div class="card-body">
              <apx-chart [series]="basicApexPieChartOptions.series" [chart]="basicApexPieChartOptions.chart"
                [labels]="basicApexPieChartOptions.labels" [legend]="basicApexPieChartOptions.legend"
                [dataLabels]="basicApexPieChartOptions.dataLabels" [fill]="basicApexPieChartOptions.fill"
                [responsive]="basicApexPieChartOptions.responsive"></apx-chart>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="card pay apex-chart-card">
            <div class="card-title text-center card-title"> Donut with Pattern Pie Chart</div>
            <div class="card-body">
              <apx-chart [series]="basicApexPatternPieChartOptions.series"
                [chart]="basicApexPatternPieChartOptions.chart" [labels]="basicApexPatternPieChartOptions.labels"
                [dataLabels]="basicApexPatternPieChartOptions.dataLabels"
                [title]="basicApexPatternPieChartOptions.title" [fill]="basicApexPatternPieChartOptions.fill"
                [states]="basicApexPatternPieChartOptions.states"
                [plotOptions]="basicApexPatternPieChartOptions.plotOptions"
                [stroke]="basicApexPatternPieChartOptions.stroke" [theme]="basicApexPatternPieChartOptions.theme"
                [responsive]="basicApexPatternPieChartOptions.responsive"></apx-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-6 col-md-6">
          <div class="card pay apex-chart-card">
            <div class="card-title text-center card-title"> Line with Data Labels </div>
            <div class="card-body">
              <apx-chart [series]="basicApexLineChartOptions.series" [chart]="basicApexLineChartOptions.chart"
                [xaxis]="basicApexLineChartOptions.xaxis" [stroke]="basicApexLineChartOptions.stroke"
                [colors]="basicApexLineChartOptions.colors" [dataLabels]="basicApexLineChartOptions.dataLabels"
                [legend]="basicApexLineChartOptions.legend" [markers]="basicApexLineChartOptions.markers"
                [grid]="basicApexLineChartOptions.grid" [yaxis]="basicApexLineChartOptions.yaxis"
                [title]="basicApexLineChartOptions.title" [responsive]="basicApexLineChartOptions.responsive"></apx-chart>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6">
          <div class="card pay apex-chart-card">
            <div class="card-title text-center card-title"> Polar Area Charts </div>
            <div class="card-body">
              <apx-chart [series]="basicApexPolarAreaChartOptions.series" [chart]="basicApexPolarAreaChartOptions.chart"
                [labels]="basicApexPolarAreaChartOptions.labels" [fill]="basicApexPolarAreaChartOptions.fill"
                [stroke]="basicApexPolarAreaChartOptions.stroke"
                [responsive]="basicApexPolarAreaChartOptions.responsive"></apx-chart>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Main row -->
</section>
<!-- /.content -->