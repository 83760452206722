import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { Subject, filter } from 'rxjs';
import { CommonService } from '@modules/shared/services/common.service';
import { AuthService } from '@services/auth.service';
import { AlertService } from '@modules/shared/services/alert.service';
import { JsonData } from '@/constant/JsonData';

@Injectable({
    providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
    previousPath = '/';
    constructor(
        private router: Router,
        private _commonService: CommonService,
        private _authservice: AuthService,
        private _alertService: AlertService,
    ) {
        router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.previousPath = event.url;
                this._commonService.setItem('previousPath', this.previousPath);
            });
    }
    
    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        let menu = next.data['menu'];
        if (menu.toLowerCase() == 'na') {
            return true;
        } else {
            // let param = {
            //     "email": JSON.parse(this._commonService.getItem('user_data')).email
            // }
            return new Promise(resp => {
                if (!this._authservice.userAccess)
                    this._authservice.userAccess = new Subject();
                this._authservice.userAccess.next(JsonData.user.userAccess);
                resp(this.permission(JsonData.user.userAccess, menu));
                // this._authservice.getAccessList(param).subscribe({
                //     next: async (res: any) => {
                //         if (res.status) {
                //             this._commonService.setItem('user_access', JSON.stringify(res.data));
                            
                //             if (!this._authservice.userAccess)
                //                 this._authservice.userAccess = new Subject();
                //             this._authservice.userAccess.next(res.data);

                //             resp(this.permission(res.data, menu));
                //         } else {
                //             this._alertService.showAlert('Access denied Please contact administrator', 'info');
                //             this.router.navigate([this.previousPath]);
                //             resp(false)
                //         }
                //     },
                //     error: (error: any) => {
                //         this._alertService.showAlert('Access denied Please contact administrator', 'info');
                //         this.router.navigate([this.previousPath]);
                //         resp(false);
                //     }
                // });
            });
        }
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): any {
        return this.canActivate(next, state);
    }

    permission(data, menu): boolean {
        const accessControl = data;
        let permission = false;
        accessControl.forEach(element => {
            if (element.name.toLowerCase() == menu.toLowerCase()) {
                element.sub_menu.forEach(item => {
                    if (item.name.toLowerCase() == 'view') {
                        permission = true;
                    }
                })
            }
        });
        if (!permission) {
            this.previousPath = localStorage.getItem('previousPath');
            this.router.navigateByUrl(this.previousPath);
            this._alertService.showAlert('Access denied Please contact administrator', 'info');
        }
        return permission;
    }
}
