import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, registerLocaleData, DatePipe} from '@angular/common';
import localeEn from '@angular/common/locales/en';

/* npm */
import {ToastrModule} from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DataTablesModule } from 'angular-datatables';
import { SocialLoginModule, SocialAuthServiceConfig,GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from '@abacritt/angularx-social-login';
import { NgApexchartsModule } from 'ng-apexcharts';

/* App Component and App Routing  */
import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';

/* Component & Modules*/
import {MainComponent} from '@modules/main/main.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {UserComponent} from '@modules/main/header/user/user.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';

import { SharedModule } from '@modules/shared/shared.module';
import { TokenInterceptorInterceptor } from '@modules/shared/services/token-interceptor.interceptor';

import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';

import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';

import {LoginComponent} from '@pages/login/login.component';
import {RegisterComponent} from '@pages/register/register.component';
import {ForgotPasswordComponent} from '@pages/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@pages/recover-password/recover-password.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import { AccessControlListComponent } from '@pages/access-control-list/access-control-list.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { ContentManagerComponent } from './pages/content-manager/content-manager.component';
 
import {NgCircleProgressModule } from 'ng-circle-progress';
import {PromoListComponent } from '@pages/promo-code/promo-list/promo-list.component';
 
import { TagInputModule } from 'ngx-chips';
import { SubscriptionsListComponent } from './pages/subscriptions/subscriptions-list/subscriptions-list.component';
import { AddEditSubscriptionsComponent } from './pages/subscriptions/add-edit-subscriptions/add-edit-subscriptions.component';
import { DisableTypingDirective } from './directives/disable-typing.directive';


registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        AccessControlListComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        SubscriptionComponent,
        ContentManagerComponent,
        PromoListComponent,
        SubscriptionsListComponent,
        AddEditSubscriptionsComponent,
        DisableTypingDirective
        
    ],
    imports: [
        ProfabricComponentsModule,
        CommonModule,
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        //StoreModule.forRoot({ui: uiReducer}),
        HttpClientModule,
        SharedModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true
        }),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        DataTablesModule,
        SocialLoginModule,
        TagInputModule,
        //GoogleSigninButtonModule,
        NgApexchartsModule,
         // Specify ng-circle-progress as an import
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 60,
      outerStrokeWidth: 10,
      space: 4,
      innerStrokeWidth: 5,
      responsive:false,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596"
    })
    ],
    exports: [SharedModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS, 
            useClass: TokenInterceptorInterceptor, 
            multi: true
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
              autoLogin: false,
              providers: [
                // {
                //   id: GoogleLoginProvider.PROVIDER_ID,
                //   provider: new GoogleLoginProvider(
                //     '58323982186-vtmmbscrd9bs79kkg59b6umuiiu571ve.apps.googleusercontent.com'
                //   )
                // },
                {
                  id: FacebookLoginProvider.PROVIDER_ID,
                  provider: new FacebookLoginProvider('2178972242444306')
                }
              ],
              onError: (err) => {
                console.error(err);
              }
            } as SocialAuthServiceConfig,
        },
        DatePipe
    ],
    schemas: [
        //CUSTOM_ELEMENTS_SCHEMA
      ],
    bootstrap: [AppComponent]
})
export class AppModule {}
