<section class="content">
    <div class="container-fluid pt-2">
        <div class="custom-table-design headingTop">

      <!-- custom search section -->
    <!--    <div class="row mt-4">
        <div class="col-lg-12">
            <div class="card pb-3">
                <div class="card-body">
                    <div class="">
                        <div class="row mt-1">
                            <div class="col-md-12">
                                <h5 class="mb-3 heading-font">Subscriptions</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-6 frmGrp">
                               
                                    <input type="text" [(ngModel)]="startDate" placeholder="Start date"  bsDatepicker [bsConfig]="bsConfig"
                                        class="form-control height-eq ng-untouched ng-pristine ng-valid">
                               
                            </div>
                            <div class="col-lg-4 col-md-4 col-6 frmGrp">
                               

                                <select  class="form-control height-eq ng-untouched ng-pristine ng-valid" [(ngModel)]="selectTimeFrame" >
                                    <option hidden value="" disabled selected>Time Frame </option>
                                    <option *ngFor="let item of timeFrame" [value]="item">{{item}}</option>
                                </select>
                         
 
                            </div>
                            <div class="col-lg-4 col-md-4 col-6 frmGrp">
                                
                                    <input type="text"  [(ngModel)]="searchText" placeholder="Search"
                                        class="form-control height-eq ng-untouched ng-pristine ng-valid">
                           
                            </div>
                        </div>
                        <div class="row mt-4 advance-search-btn">
                            <div class="col-lg-12 col-md-6 col-12 text-center btn-part srchFrmBtn">
                                <button class="btn btn-primary mr-2" (click)="applyFilters()">Search</button>
                                <button class="btn btn-primary mr-2">Reset</button>
                                <button (click)="downloadCSV()" class="btn btn-primary">Download CSV</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div> -->
            <h2 class="heading-font">Subscriptions</h2>
        <!--    <input type="text" id="searchInput" placeholder="Search..."> -->

          <!--      <button class="btn btn-primary custom-btn"
            (click)="openSubscriptionsModal(subscriptionModal, 'Add', '')">+Add
            Subscriptions</button> -->


<!--

            <div class="card">
                <div class="card-body table-responsive p-0">
                    <ng-container >
                    <table  id="dataTable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                        class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th class="stickyCol">Sl No.</th>
                                <th class="stickyCol">Package Name</th>
                                <th>Started Date</th>
                                <th>Timeframe</th>
                                <th>Price</th>
                              
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor="let item of subscriptionsList; let i = index">
                                <td>{{i+1}}</td>
                                <td>{{item?.title || 'NA'}}</td>
                                <td>{{item?.started_date}}</td>
                                <td>{{item?.timeframe || 'NA'}}</td>
                                <td>{{item?.price || '0'}}%</td>
                               
                                <td class="actionBlock">
                                    <a class="fwl-icon-bg" tooltip="edit"
                                        (click)="openSubscriptionsModal(subscriptionModal, 'Edit', item)">
                                        <span class="view"><i class="fas fa-pen me-3"></i></span>
                                    </a>
                                    <a class="fwl-icon-bg" tooltip="delete"
                                        (click)="confirmSubscriptionsDeleteBox(item)">
                                        <span class="del"><i class="fas fa-trash-alt "></i></span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
              
                  
                    </table>
                </ng-container>
                 
                </div>
            </div>
 
-->
        </div>
    </div>
</section>

<ng-template #subscriptionModal>
    <app-add-edit-subscriptions
    [modalRef]="modalRef"
    [modalType]="modalType"
    [packageList]="packageList"
    [subscriptionData]="subscriptionDetails"
    (addSubscription) = "addSubscriptionWithDetails($event)"
    (editSubscription) = "editSubscriptionWithDetails($event)"
    ></app-add-edit-subscriptions>
</ng-template>