import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountTypesService {
	private APIEndpoint = environment.baseUrl;

	constructor(private http: HttpClient) { }

	public getAllAccountTypeList(){
		return this.http.get(`${this.APIEndpoint}/get-account-type-list`);
	}

	public addAccountType(params:any){
		return this.http.post(`${this.APIEndpoint}/add-account-type`, params);
	}

	public updateAccountType(params:any){
		return this.http.post(`${this.APIEndpoint}/update-account-type`, params);
	}

	public deleteAccountType(params:any){
		return this.http.post(`${this.APIEndpoint}/delete-account-type`, params);
	}	
}
