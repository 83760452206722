import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { CommonService } from '@modules/shared/services/common.service';
import { environment } from 'environments/environment';
import { APIConstant } from '@/constant/APIConstant';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userAccess: Subject<any>;
  
  constructor(
    private http: HttpClient, 
    private router: Router, 
    private commonService: CommonService
  ){}

  signIn(params:any) {
    console.log("params")
    console.log(params)
    
  return this.http.post(`${environment.baseUrl+APIConstant.auth.login}`, params);
  
 // return this.http.post(`https://dev-api.dreamcrazy.app/api/v1/admin-signin`, params);
  }

  signOut(params) {
   
   // localStorage.removeItem('access_token');
 
   const logoutValue = {
    'access_token':   params // Assuming getToken() returns the token
    };
    console.log(logoutValue)
   return this.http.post(`${environment.baseUrl+APIConstant.auth.logout}`, logoutValue);
    if (this.userAccess) {
      this.userAccess.unsubscribe();
    }
    this.router.navigate(['/login']);
  }

  sendOTP(params:any) {
   // return this.http.post(`${environment.baseUrl+APIConstant.auth.forgotPassword}`, params,{observe: 'response'});
  }

  renewForgetPassword(params:any) {
    //return this.http.post(`${environment.baseUrl+APIConstant.auth.setNewPassWord}`, params,{observe: 'response'});
  }

  refreshToken(token: string) {
   // return this.http.post(environment.baseUrl + APIConstant.auth.refreshToken, {
   //   refreshToken: token
   // });
  }

  getAccessList(params):any {
   // return this.http.post(environment.baseUrl + APIConstant.auth.accessPermission, params);
  }

}
