<div class="row">
    <div class="col-lg-7 log-reg-side-bg">
        <div class="cntntRightArea">
            <h3>It's time to put your</h3>
            <h2>Peace of mind first</h2>
            <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the
                visual form of a document or a typeface without relying on</p>
        </div>
    </div>

    <div class="col-lg-5 box-align">
        <div class="login-box">
            <div class="">
                <div class="card-header text-center mb-4 border-0">
                    <a [routerLink]="['/']" class="h1"><img src="../../../assets/img/logo-main.png"></a>
                </div>
                <div class="card-body">
                    <!-- <p class="login-box-msg">Sign in to start your session</p> -->

                    <form [formGroup]="registerForm" (ngSubmit)="signUp()" autocomplete="off">
                        <div class="mb-3">
                            <input formControlName="name" type="text" class="form-control"
                                placeholder="Username">

                            <div *ngIf="registerForm.controls['name'].invalid && registerForm.controls['name'].errors!=null && (registerForm.controls['name'].dirty || registerForm.controls['name'].touched)"
                                class="alert alert-danger mt-2">
                                <div
                                    *ngIf="registerForm.controls['name'].errors['required']">
                                    This field is required.
                                </div>
                            </div>
                        </div>

                        <div class="mb-3">
                            <input formControlName="email" type="email" class="form-control" placeholder="Email" email/>
                            <div *ngIf="registerForm.controls['email'].invalid && (registerForm.controls['email'].dirty || registerForm.controls['email'].touched)"
                                class="alert alert-danger mt-2">
                                <div *ngIf="registerForm.controls['email'].errors?.['required'] || registerForm.controls['email'].errors?.['email']">
                                    Please enter valid email id
                                </div>
                            </div>
                        </div>
                        
                        <div class="mb-3 position-relative">
                            <input formControlName="password" [type]="passwordHide ? 'password' : 'text'" class="form-control"
                                placeholder="Password"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$"/>
                            <div class="position-absolute custom-position">
                                <span [ngClass]="passwordHide ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                        (click)="togglemyPasswordFieldType('password')"></span>
                            </div>

                            <div *ngIf="registerForm.controls['password'].invalid && (registerForm.controls['password'].dirty || registerForm.controls['password'].touched)"
                            class="alert alert-danger mt-2">
                                <div *ngIf="registerForm.controls['password'].errors?.['required']">
                                    Please enter your password
                                </div>
                                <div *ngIf="registerForm.controls['password'].errors['pattern']">
                                    Password needs to have minimum six characters, at least one uppercase
                                    letter, one lowercase letter, one number and one special character
                                </div>
                            </div>
                        </div>

                        <div class="mb-3 position-relative">
                            <input formControlName="confirmPassword" [type]="confirmPasswordHide ? 'password' : 'text'" class="form-control"
                                placeholder="Confirm password" />
                            <div class="position-absolute custom-position">
                                <span [ngClass]="confirmPasswordHide ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                        (click)="togglemyPasswordFieldType('confirm_password')"></span>
                            </div>
                            
                            <div *ngIf="registerForm.controls['confirmPassword']?.invalid && registerForm.controls['confirmPassword'].errors!=null && (registerForm.controls['confirmPassword'].dirty || registerForm.controls['confirmPassword'].touched)"
                                    class="alert alert-danger mt-2">
                                <div *ngIf="registerForm.controls['confirmPassword'].errors['required']">
                                    Please re-enter your password
                                </div>
                            </div>
                            <div *ngIf="registerForm.controls['confirmPassword']?.valid && registerForm.errors?.['PasswordNoMatch'] && (registerForm.controls['confirmPassword'].dirty || registerForm.controls['confirmPassword'].touched)" class="alert alert-danger mt-2">
                                <div>Password does'nt match.</div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-12 mb-3">
                                <div class="icheck-primary">
                                    <input type="checkbox" id="agreeTerms" name="terms" value="agree" />
                                    <label for="agreeTerms">
                                        I agree to the <a href="#">terms</a>
                                    </label>
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <button type="submit" class="btn btn-primary d-block w-100 green-bg" [disabled]="registerForm.invalid">Register</button>
                            </div>

                            <div class="col-12 mb-3">
                                <button class="btn btn-primary d-block w-100" [routerLink]="['/login']"> I already have
                                    a membership</button>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- /.login-card-body -->
            </div>
        </div>
    </div>
</div>