<section class="content">
    <div class="container-fluid pt-2">
        <!-- Small boxes (Stat box) -->
        <div class="custom-table-design">

            <div class="row">
                <div class="col-lg-6">
                    <h5 class="heading-font">Manage Access Control List</h5>
                </div>
                <!-- <div class="col-lg-6 text-right">
                    <button class="btn btn-primary custom-btn">+Add</button>
                </div> -->
            </div>
            <hr>
            <div class="card">
                <!-- /.card-header -->
                <div class="card-body table-responsive p-0">
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                        class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th class="stickyCol">Sl No.</th>
                                <th class="stickyCol">Account Type</th>
                                <th class="text-left">User Management</th>
                                <th class="text-left">Account Type Management</th>
                                <th class="text-left">ACL Management</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="accessControlList.length > 0">
                            <tr *ngFor="let accessControl of accessControlList; let i = index">
                                <td>{{i+1}}</td>
                                <td>{{accessControl.name}}</td>
                                <td>
                                    <div class="switch">
                                        <label class="toggleSwitch nolabel">
                                            <input type="checkbox" [checked]="accessControl.user_management=='yes'" (click)="changeAccess(i, accessControl, 'user_management')"/>
                                            <span>
                                                <span>YES</span>
                                                <span>NO</span>
                                            </span>
                                            <a></a>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="switch">
                                        <label class="toggleSwitch nolabel" onclick="">
                                            <input type="checkbox" [checked]="accessControl.account_type_management=='yes'" (click)="changeAccess(i, accessControl, 'account_type_management')"/>
                                            <span>
                                                <span>YES</span>
                                                <span>NO</span>
                                            </span>
                                            <a></a>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="switch">
                                        <label class="toggleSwitch nolabel" onclick="">
                                            <input type="checkbox" [checked]="accessControl.acl_management=='yes'" (click)="changeAccess(i, accessControl, 'acl_management')"/>
                                            <span>
                                                <span>YES</span>
                                                <span>NO</span>
                                            </span>
                                            <a></a>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="accessControlList.length == 0">
                            <tr>
                                <td class="text-center" colspan="7">No Data Available!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>