import { APIConstant } from '@/constant/APIConstant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromoService {
  private APIEndpoint = environment.baseUrl;

	constructor(private http: HttpClient) { }

	public getAllPromoCodeList(page: number, size: number,search : any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		  const params = {
		 
				"package_id":1
	 
		  }
		return this.http.get(`${this.APIEndpoint+APIConstant.promoCode.getPromoCodeList+'?page='+page+'&page_size='+size+'&search='+search+''}`,  { headers });
	}
	public getPromoCodeListCSV(page: number, size: number,search : any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		  const params = {
		 
				"package_id":1
	 
		  }
		return this.http.get(`${this.APIEndpoint+APIConstant.promoCode.getPromoCodeList+'?page='+page+'&page_size='+size+'&search='+search+''}`,  { headers });
	}
	

	public addPromoCode(params:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		return this.http.post(`${this.APIEndpoint+APIConstant.promoCode.addPromoCode}`, params, {headers});
	}

	public updatePromoCode(params:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		return this.http.post(`${this.APIEndpoint+APIConstant.promoCode.editPromoCode}`, params,  {headers});
	}
	public getPackageList(value:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		  const params = {
		 
			"platform":value
 
	  }
		return this.http.post(`${this.APIEndpoint+APIConstant.promoCode.getPackageList}`, params,  {headers});
	}
	public getDiscountList(value:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		  const params = {
		 
			"package_id":value
 
	  }
		return this.http.post(`${this.APIEndpoint+APIConstant.promoCode.getDiscountList}`, params,  {headers});
	}
	public deletePromoCode(value:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });

		  const params = {
		 
			"coupon_id":value
 
	 		 }
		return this.http.delete(`${this.APIEndpoint+APIConstant.promoCode.deletePromoCode}`, {headers: headers,body:params});
	}	


 







	getToken(){
		return localStorage.getItem('access_token');
	}
}
