import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-add-edit-subscriptions',
  templateUrl: './add-edit-subscriptions.component.html',
  styleUrl: './add-edit-subscriptions.component.scss'
})
export class AddEditSubscriptionsComponent  implements OnInit{
  
    
  @Input() modalRef: any;
  @Input() modalType: string;
  @Input() packageList: any;
  @Input() subscriptionData: any;
  @Output() addSubscription = new EventEmitter();
  @Output() editSubscription = new EventEmitter();

  public subscriptionForm!: FormGroup;

  bsConfig: any = {};

  constructor(
    private fb: FormBuilder,
    private _datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.initSubscriptionForm();
  }
 
  public initSubscriptionForm() {
    this.bsConfig = Object.assign({}, {
			containerClass: 'theme-blue',
			showWeekNumbers: false,
			dateInputFormat: 'MM-DD-YYYY',
			isAnimated: true
		});
  
    var validity = ''
    if (this.subscriptionData.validity !== '') {
      validity = this._datePipe.transform(this.subscriptionData.validity, 'MM-dd-yyyy');
    } else {
      validity = '';
    }

    this.subscriptionForm = this.fb.group({
      title: this.fb.control(this.subscriptionData?.title, [Validators.required]),
      code: this.fb.control(this.subscriptionData?.code, [Validators.required]),
      package_id: this.fb.control(this.subscriptionData?.package_id, [Validators.required]),
      discount: this.fb.control(this.subscriptionData?.discount, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(100)]),
      maximum_uses: this.fb.control(this.subscriptionData?.maximum_uses, [Validators.required, Validators.pattern("^[0-9]*$")]),
      validity: this.fb.control(validity, [Validators.required]),
    });
  }
  
  public submitAddSubscriptionForm() {
    let formValue = this.subscriptionForm.value;
    formValue.validity = this._datePipe.transform(formValue.validity, 'yyyy-MM-dd');
    console.log(formValue);
    this.addSubscription.emit(formValue);
  }

  public submitEditSubscriptionForm() {
    let formValue = this.subscriptionForm.value;
    formValue.id = this.subscriptionData.id;
    formValue.validity = this._datePipe.transform(formValue.validity, 'yyyy-MM-dd');
    console.log(formValue);
    // let params = {
    //   id: this.subscriptionData.id,
    //   title: formValue.title
    // }
    this.editSubscription.emit(formValue);
  }


}
