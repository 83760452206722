export const APIConstant = {
    auth: {
      // login: '/tech-team-signin',
       login: '/api/v1/admin-signin',
       logout: '/api/v1/admin-signout',
    //   registration: '/tech-team-signup',
      //  forgotPassword: '/tech-team-forgot-password',
      //  verifyEmail: '/tech-team-email-validate',
      //  setNewPassWord: '/set-password',
      //  refreshToken: '/refresh-token',
      //  accessPermission: '/permission-list',
    },
    content: {
      //  getContentList: '/videos/videos/',
      
      getGroupList:'/api/v1/content_groups',
        getContentGroupList:'/api/v1/admin-all-contents',
        addContent: '/api/v1/admin-upload-video',
       editContent: '/api/v1/admin-content-edit',
       deleteContent: '/api/v1/admin-content/'
    },
    promoCode: {
      getPromoCodeList: '/api/v1/coupon/all',
      getPackageList: '/api/v1/packages',
      getDiscountList: '/api/v1/discounts/package',
      addPromoCode: '/api/v1/coupons/create',
      editPromoCode: '/tech-team-edit-coupon',
      deletePromoCode: '/api/v1/coupon/delete'
  },
    profile: {
        getProfileDetails: '/tech-team-get-profile',
        editProfileDetails: '/tech-team-edit-profile',
    },
    user: {
        getUsersList: '/tech-team-get-user-list',
        addUser: '/tech-team-create-profile',
        editUser: '/tech-team-edit-profile',
        deleteUser: '/tech-team-delete-user'
    },
    accessGroup: {
        permissionList: '/permission-list',
        addPermission: '/permission-given-to-group',
        editPermission: '/update-permission-given-to-group',
        accessGroupList: '/access-group-list',
        deleteAccessGroup: '/delete-access-group'
    }
}