import {Injectable} from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router
} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from '@services/app.service';

import { AuthService } from '@services/auth.service';
import { CommonService } from '@modules/shared/services/common.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private appService: AppService,
        private _authService: AuthService,
        private _commonService: CommonService,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.isAuthorized();
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this.canActivate(next, state);
    }

    // isAuthorized = (state:any): any => { 
    //     return true;
    // }

    async isAuthorized() {
        // if (localStorage.getItem('token')) {
        //     return true;
        // }else{
        //     this.router.navigate(['/Login']);
        //     return false;
        // }
        if (this._commonService.getItem('access_token')) 
        {
            console.log('access_token exists');
            return true;
        } else {
            console.log('access_token not exists');
            this.router.navigate(['/login']);
            return false;
        }
    }
}
