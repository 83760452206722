import { APIConstant } from '@/constant/APIConstant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  private APIEndpoint = environment.baseUrl;

	constructor(private http: HttpClient) { }

	public getAllSubscriptionsList(){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		return this.http.get(`${this.APIEndpoint+APIConstant.promoCode.getPromoCodeList}`, { headers });
	}

	public addSubscriptions(params:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		return this.http.post(`${this.APIEndpoint+APIConstant.promoCode.addPromoCode}`, params, {headers});
	}

	public updateSubscriptions(params:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		return this.http.post(`${this.APIEndpoint+APIConstant.promoCode.editPromoCode}`, params,  {headers});
	}

	public deleteSubscriptions(params:any){
		const headers = new HttpHeaders({
			'Authorization': 'Bearer ' + this.getToken() // Assuming getToken() returns the token
		  });
		return this.http.post(`${this.APIEndpoint+APIConstant.promoCode.deletePromoCode+params}`,  { headers });
	}	
	getToken(){
		return localStorage.getItem('access_token');
	}
}
