import { Component } from '@angular/core';
import { delay, tap } from 'rxjs';
import { LoaderService } from '@modules/shared/services/loader.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public isLoaderOn: boolean = false;

    constructor(
        private _loaderService: LoaderService,
    ) { }

    ngAfterViewInit() {
        // add delay in subscribtion to facilitate change detection
        this._loaderService.castLoader
            .pipe(
                delay(0),
                tap((loader) => this.loader(loader))
            ).subscribe();
    }

    public loader(data: any) {
        if (data === 'showloader') {
            this.isLoaderOn = true;
        } else if (data === 'hideloader') {
            this.isLoaderOn = false;
        }
    }
}
