import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertService } from '@modules/shared/services/alert.service';
import { CommonService } from '@modules/shared/services/common.service';
import { LoaderService } from '@modules/shared/services/loader.service';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { PromoService } from '../_services/promo.service';
import { CsvService } from '@services/csv.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Config } from 'datatables.net';
declare var $: any;
@Component({
  selector: 'app-promo-list',
  templateUrl: './promo-list.component.html',
  styleUrl: './promo-list.component.scss'
})

export class PromoListComponent implements OnInit, AfterViewInit {
  @ViewChild('addPromocodeModalClose') addPromocodeModalClose!: ElementRef;
  public promoList: any ;
  public totalPromos: any;
  public promoListAllData: any;
  public userData: any;
  public promocodeDetails: any;
  public promos: any;
  public pakagEStatickId = 1;
  public promocodeForm!: FormGroup;
  promocodeData:any;
  selectedDataForDiscount:any;
  minDate:any = new Date();
  selectedValue: string = '';
  selectedDevice: string = '';
  selecteDiscountPrice: string = '';
  selectedData:any;
  bsConfig: any = {};
/******pagination***** */
totalItems: number = 0;
currentPage: number = 1;
itemsPerPage: number = 10;
totalPages: number = 0;
/***search */
searchText: string = '';
filteredData:any;
  /* DataTable */
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  /* dt Ends */

  public modalType: string = "";
  /* ModelConfig */
  modalRef: BsModalRef;
  config = {
    animated: true,
    backdrop: true,
    keyboard: false,
    ignoreBackdropClick: true
  };
  /* ModelConfig Ends*/
 
 

  constructor(
    private _commonService: CommonService,
    private fb: FormBuilder,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _promo: PromoService,
    private modalService: BsModalService,
    private _csvService:CsvService,
    private _datePipe: DatePipe,
  ) { this.minDate.setHours(0, 0, 0, 0); }

  ngOnInit(): void {
    this.initPromocodeForm();
    this.getPromoCodeList();
  

  //  this.userData = this._commonService.getItem('user_data');
    //this.userData = JSON.parse(this.userData);
    //console.log(this.userData);
   this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2,
      ordering: false // Disables sorting for the entire table
    };
    
 
   
  }
  ngAfterViewInit(): void {
    $(document).ready(function () {


      $('#searchInput').on('keyup', function () {

        $('#dataTable').DataTable().search($(this).val()).draw();

      });
    });
  }
  /**
   * Method         : getPromoCodeList
   * Description    : fetch list of existing coupons
   */
  public getPromoCodeList() {
    this._loaderService.showLoader();
    
    this._promo.getAllPromoCodeList(this.currentPage, this.itemsPerPage, this.searchText).subscribe({
      next: (resp: any) => {
        if (resp.status) {
          this.promos = [...resp.data.coupons];;
          console.log(resp.data.total_pages)
          this.totalPages = resp.data.total_pages;
        //  console.log(this.totalPages)
          console.log(resp.data)
          console.log(resp.data.total_coupons)
          this.totalItems = resp.data.total_coupons;
          this.promoListAllData = this.promos;
          // this.filteredData = this.subscriptionsListData
          // console.log(this.searchFilter())
           this.promoList = this.promoListAllData;
          console.log(this.promoList);
          this.dtTrigger.next('1');
        // this.dtTrigger.next(1);
          this._loaderService.hideLoader();
        } else {
          this._loaderService.hideLoader();
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        console.error(error);
      }
    });

  }

 



  /****** add promocode  */
  resetForm(){
   //this.promocodeForm.controls['discount_package_id'].reset(); 
   this.promocodeForm.get('device_type')?.reset('', { emitEvent: false }); 
   this.promocodeForm.get('package_id')?.reset('', { emitEvent: false }); 
   
   this.promocodeForm.get('discount_package_id')?.reset('', { emitEvent: false });
   this.promocodeForm.controls['coupon_name'].reset(); 
   this.promocodeForm.controls['maximum_uses'].reset(); 
   this.promocodeForm.controls['valid_to'].reset();
   //this.promocodeForm.controls['coupon_code'].reset();
   this.selectedData = null;
   this.selectedDataForDiscount = null;

    //this.assignNullValue();
   
  }
  assignNullValue(){
 
    this.selecteDiscountPrice = '';
 
 
  }
  public initPromocodeForm() {
    
    this.bsConfig = Object.assign({}, {
			containerClass: 'theme-blue',
			showWeekNumbers: false,
      minDate: this.minDate,
			dateInputFormat: 'DD-MM-YYYY',
			isAnimated: true
		});
  
 
    this.promocodeForm = this.fb.group({
      device_type: this.fb.control('', [Validators.required]),
      package_id: this.fb.control('', [Validators.required]),
     // discount: this.fb.control(this.promocodeData?.discount, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.max(100)]),
     discount_package_id: this.fb.control('', [Validators.required]),
      coupon_name: this.fb.control('', [Validators.required]),
      // maximum_uses: this.fb.control(this.promocodeData?.maximum_uses, [Validators.required, Validators.pattern("^[0-9]*$")]),
      maximum_uses: this.fb.control('', [Validators.required,  Validators.pattern("^[0-9]*$")]),
      
     // code: this.fb.control(this.promocodeData?.code, [Validators.required]),
      
    
     valid_to: this.fb.control('', [Validators.required]),

  //   coupon_code: this.fb.control('', [Validators.required])
    });
  }
  onChange(value:any){
    this._loaderService.showLoader();
 this.selectedValue = '';
 this.selecteDiscountPrice = '';
    this._promo.getPackageList(value).subscribe(
      (data: any) => {
        this.selectedData = data.data.packages;
        
          // Assigning value using setValue() method
   
      
       console.log(this.selectedData)
       this._loaderService.hideLoader();
      },
      (error) => {
        console.error('Error fetching data by ID', error);
        this._loaderService.hideLoader();
      }
    )

  }
  onChangePakage(value:any){
    this._loaderService.showLoader();
    this._promo.getDiscountList(value).subscribe(
      (data: any) => {
        this.selectedDataForDiscount = data.data.discounts;
        
          // Assigning value using setValue() method
   
      
       console.log(this.selectedDataForDiscount)
       this._loaderService.hideLoader();
      },
      (error) => {
        console.error('Error fetching data by ID', error);
        this._loaderService.hideLoader();
      }
    )
 
  }
  public submitAddPromocodeForm() {
    let formValue = this.promocodeForm.value;
    formValue.coupon_code = this.promocodeForm.controls['coupon_name'].value;
     formValue.valid_to = this._datePipe.transform(formValue.valid_to, 'dd-MM-yyyy');
    console.log(formValue);
    if (this.promocodeForm.valid) {
      this.submitPromoCode(formValue);
    }
    else {
      this._alertService.showAlert("Invalid Form", 'error');
    }
  }
  submitPromoCode(formData: FormData){
   // const myModalEl = document.getElementById('addPromocodeModalClose');
    this._promo.addPromoCode(formData).subscribe({
      next: (resp: any) => { 
        this._alertService.showAlert("File uploaded successfully!", 'success');
        this.addPromocodeModalClose.nativeElement.click();
        this.getPromoCodeList();
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        //this._alertService.showAlert("File uploaded successfully!", 'error');
        this._alertService.showAlert(error.error.message, 'error');
       // console.log("error");
      //  console.log(error.error.message);
      //  console.log("erro111r");

      }
    })
  }
  
  /**
  * Method         : confirmCouponDeleteBox
  * Description    : takes use's permission before deleting any existing coupon
  */
  confirmPromocodeDeleteBox(promo: any) {
    //alert(promo.id)
    let params =   promo.id;

console.log(params);
    Swal.fire({
      title: 'Delete Promo Code',
      text: 'Are you sure you want to delete promo code? This process is irreversible and won’t be able to be used again.',
     // icon: 'warning',
      showCancelButton: false,
      showCloseButton: true,
      confirmButtonText: 'Delete Promo Code',
     // cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._promo.deletePromoCode(params).subscribe(
          (resp: any) => {
            console.log(resp);
      
            this._alertService.showAlert(resp.message, 'success');
            this.getPromoCodeList();
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Promo Code record is not deleted :)',
          'error'
        )
      }
    })
  }
/****Pagination Search****** */
pageChanged(page: number): void {
  this.currentPage = page;
 // alert(page);
  this.getPromoCodeList();
}

onSearchChange(searchValue: string): void {
 // this.payload.search = searchValue;
 this.currentPage = 1;
 this.searchText = searchValue;
  this.getPromoCodeList();
}
onFilterChange(filterValue: string): void {
  //this.payload.filter = filterValue;
  this.getPromoCodeList();
}

getTotalPages(): number {
  return this.totalPages;
  return Math.ceil(this.totalItems / this.itemsPerPage);
}

getPages(): number[] {
  const totalPages = this.getTotalPages();
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  return pages;
}

resetSearch(){
  this.searchText = '';
  this.currentPage = 1;
  this.getPromoCodeList();
}

downloadCSV(){

  this.getPromoCodeListForCSV();
 
  
}

getPromoCodeListForCSV(){
  this.currentPage = 1;
  console.log(this.totalItems);
  this._promo.getPromoCodeListCSV(this.currentPage, this.totalItems, this.searchText).subscribe({
    next: (resp: any) => {
      if (resp.status) {
        this.totalPromos = [...resp.data.coupons];;
       
        console.log(this.totalPromos)
        this._csvService.downloadCsv(this.totalPromos, 'data.csv');
        this._loaderService.hideLoader();
      } else {
        this._loaderService.hideLoader();
      }
    },
    error: (error: any) => {
      this._loaderService.hideLoader();
      console.error(error);
    }
  })
}


 
}
