import { Component, OnInit } from '@angular/core';
import ApexCharts from 'apexcharts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public barChartData = [
    ['New York City, NY', 8175000],
    ['Los Angeles, CA', 3792000],
    ['Chicago, IL', 2695000],
    ['Houston, TX', 2099000],
    ['Philadelphia, PA', 1526000]
  ];

  public columnChartData = [
    ['New York City, NY', 8175000],
    ['Los Angeles, CA', 3792000],
    ['Chicago, IL', 2695000],
    ['Houston, TX', 2099000],
    ['Philadelphia, PA', 1526000]
  ];

  public pieChartData = [
    ['New York City, NY', 8175000],
    ['Los Angeles, CA', 3792000],
    ['Chicago, IL', 2695000],
    ['Houston, TX', 2099000],
    ['Philadelphia, PA', 1526000]
  ];

  browserData = [
    ['Firefox', 45.0],
    ['IE', 26.8],
    ['Chrome', 12.8],
    ['Safari', 8.5],
    ['Opera', 6.2],
    ['Others', 0.7]
  ];

  languageData = [
    ['Assamese', 13], ['Bengali', 83], ['Bodo', 1.4],
    ['Dogri', 2.3], ['Gujarati', 46], ['Hindi', 300],
    ['Kannada', 38], ['Kashmiri', 5.5], ['Konkani', 5],
    ['Maithili', 20], ['Malayalam', 33], ['Manipuri', 1.5],
    ['Marathi', 72], ['Nepali', 2.9], ['Oriya', 33],
    ['Punjabi', 29], ['Sanskrit', 0.01], ['Santhali', 6.5],
    ['Sindhi', 2.5], ['Tamil', 61], ['Telugu', 74], ['Urdu', 52]
  ];

  companyPerformanceData = [
    ['2004', 1000, 400],
    ['2005', 1170, 460],
    ['2006', 660, 1120],
    ['2007', 1030, 540]
  ];

  boxOfficeEarningsData = [
    [1, 37.8, 80.8, 41.8],
    [2, 30.9, 69.5, 32.4],
    [3, 25.4, 57, 25.7],
    [4, 11.7, 18.8, 10.5],
    [5, 11.9, 17.6, 10.4],
    [6, 8.8, 13.6, 7.7],
    [7, 7.6, 12.3, 9.6],
    [8, 12.3, 29.2, 10.6],
    [9, 16.9, 42.9, 14.8],
    [10, 12.8, 30.9, 11.6],
    [11, 5.3, 7.9, 4.7],
    [12, 6.6, 8.4, 5.2],
    [13, 4.8, 6.3, 3.6],
    [14, 4.2, 6.2, 3.4]
  ];

  basicApexBarChartOptions: any;
  basicApexBarChart: any;

  basicApexColumnChartOptions: any;
  basicApexColumnChart: any;

  basicApexPieChartOptions: any;
  basicApexPatternPieChartOptions: any;

  basicApexLineChartOptions: any;
  basicApexPolarAreaChartOptions: any;

  ngOnInit(): void {
    this.apexBarChart();
    this.apexColumnChart();
    this.apexPieChart();
    this.apexPatternPieChart();
    this.apexLineChart();
    this.apexPolarAreaChart();
  }

  public apexBarChart() {
    this.basicApexBarChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      series: [{
        data: [380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365]
      }],
      colors: ['#33a186'],
      grid: {
        borderColor: '#f1f1f1',
      },
      xaxis: {
        categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan', 'United States', 'China', 'Germany'],
      }
    }

    // you can also append this chart in any section with a specific Id 
    // instead of using <apx-chart></apx-chart>

    // if (this.basicApexBarChart) {
    //   this.basicApexBarChart.destroy();
    // }
    // this.basicApexBarChart = new ApexCharts(document.querySelector("#apex-bar-chart"), this.basicApexBarChartOptions);
    // this.basicApexBarChart.render();
  }

  public apexColumnChart() {
    this.basicApexColumnChartOptions = {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false,
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '45%',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      series: [{
        name: 'Net Profit',
        data: [46, 57, 59, 54, 62, 58, 64, 60, 66, 70]
      }, {
        name: 'Revenue',
        data: [74, 83, 102, 97, 86, 106, 93, 114, 94, 80]
      }, {
        name: 'Free Cash Flow',
        data: [37, 42, 38, 26, 47, 50, 54, 55, 43, 50]
      }],
      colors: ["#fa6374", "#3980c0", "#33a186"],
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      },
      yaxis: {
        title: {
          text: '$ (thousands)'
        }
      },
      fill: {
        opacity: 1

      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          }
        }
      }
    }
  }

  public apexPieChart() {
    this.basicApexPieChartOptions = {
      series: [44, 55, 13, 43, 22],
      chart: {
        height: 350,
        type: "pie",
        //type: "donut"
      },
      labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      legend: {
        position: "bottom",
      },
      dataLabels: {
        dropShadow: {
          enabled: false,
        },
      },
      fill: {
        type: "gradient"
      }
    };
  }

  public apexPatternPieChart() {
    this.basicApexPatternPieChartOptions = {
      series: [44, 55, 41, 17, 15],
      chart: {
        height: 350,
        type: "donut",
        dropShadow: {
          enabled: true,
          color: "#111",
          top: -1,
          left: 3,
          blur: 3,
          opacity: 0.2
        }
      },
      stroke: {
        width: 0
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      labels: ["Comedy", "Action", "SciFi", "Drama", "Horror"],
      dataLabels: {
        dropShadow: {
          blur: 3,
          opacity: 0.8
        }
      },
      fill: {
        type: "pattern",
        opacity: 1,
        pattern: {
          enabled: true,
          style: [
            "verticalLines",
            "squares",
            "horizontalLines",
            "circles",
            "slantedLines"
          ]
        }
      },
      states: {
        hover: {
          filter: {
            type: "none"
          }
        }
      },
      theme: {
        palette: "palette2"
      },
      title: {
        text: "Favourite Movie Type"
      }
    };
  }

  public apexLineChart() {
    this.basicApexLineChartOptions = {
      series: [
        {
          name: "High - 2013",
          data: [28, 29, 33, 36, 32, 32, 33]
        },
        {
          name: "Low - 2013",
          data: [12, 11, 14, 18, 17, 13, 13]
        }
      ],
      chart: {
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ["#77B6EA", "#545454"],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "Average High & Low Temperature",
        align: "left"
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        title: {
          text: "Month"
        }
      },
      yaxis: {
        title: {
          text: "Temperature"
        },
        min: 5,
        max: 40
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              //width: 200,
              height:500
            },
            legend: {
              position: "top"
            }
          }
        }
      ]
    };
  }

  public apexPolarAreaChart() {
    this.basicApexPolarAreaChartOptions = {
      series: [14, 23, 21, 17, 15, 10, 12, 17, 21],
      chart: {
        type: "polarArea",
        height: 350,
      },
      stroke: {
        colors: ["#fff"]
      },
      fill: {
        opacity: 0.8
      }
    };
  }
}
