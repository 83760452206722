<section class="content">
    <div class="container-fluid pt-2">
        <div class="custom-table-design headingTop">
            <div class="row align-items-center">
                <div class="col-sm-6 headingCol">
                    <h5 class="heading-font">Account Types</h5>
                </div>
                <div class="col-sm-6 headingCol text-right">
                    <button class="btn btn-primary custom-btn" (click)="openAccountTypeModal(accountTypeModal, 'Add', '')">+Add
                        Account Type</button>
                </div>
            </div>
            <hr>
            <div class="card">
                <div class="card-body table-responsive p-0">
                    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                        class="table table-hover text-nowrap">
                        <thead>
                            <tr>
                                <th class="stickyCol">Sl No.</th>
                                <th class="stickyCol">Account Name</th>
                                <th class="text-center">Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="accountTypeList.length > 0">
                            <tr *ngFor="let accountType of accountTypeList; let i = index">
                                <td>{{i+1}}</td>
                                <td>{{accountType.name}}</td>
                                <td class="text-center">
                                    <span class="">Active</span>
                                </td>
                                <td class="actionBlock">
                                    <a class="fwl-icon-bg" tooltip="edit" (click)="openAccountTypeModal(accountTypeModal, 'Edit', accountType)">
                                        <span class="view"><i class="fas fa-pen me-3"></i></span>
                                    </a>
                                    <a class="fwl-icon-bg" tooltip="delete" (click)="confirmAccountTypeDeleteBox(accountType)">
                                        <span class="del"><i class="fas fa-trash-alt "></i></span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="accountTypeList.length == 0">
                            <tr>
                                <td class="text-center" colspan="7">No Data Available!</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #accountTypeModal>
    <app-account-type-add-edit
    [modalRef]="modalRef"
    [modalType]="modalType"
    [accountTypeData]="accountTypeDetails"
    (addAccountType) = "addAccountTypeWithDetails($event)"
    (editAccountType) = "editAccountTypeWithDetails($event)"
    ></app-account-type-add-edit>
</ng-template>