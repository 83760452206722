import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-account-type-add-edit',
  templateUrl: './account-type-add-edit.component.html',
  styleUrl: './account-type-add-edit.component.scss'
})
export class AccountTypeAddEditComponent implements OnInit {
    
  @Input() modalRef: any;
  @Input() modalType: string;
  @Input() accountTypeData: any;
  @Output() addAccountType = new EventEmitter();
  @Output() editAccountType = new EventEmitter();

  public accountTypeForm!: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.initAccountTypeForm();
  }

  public initAccountTypeForm() {
    this.accountTypeForm = this.fb.group({
      account_type_name: this.fb.control(this.accountTypeData?.name, [Validators.required]),
    });
  }
  
  public submitAddAccountTypeForm() {
    let formValue = this.accountTypeForm.value;
    let params = {
      account_type_name: formValue.account_type_name,
    }
    this.addAccountType.emit(params);
  }

  public submitEditAccountTypeForm() {
    let formValue = this.accountTypeForm.value;
    let params = {
      id: this.accountTypeData.id,
      account_type_name: formValue.account_type_name
    }
    this.editAccountType.emit(params);
  }
}
