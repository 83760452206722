import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisableTyping]'
})
export class DisableTypingDirective {
  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  handlePasteEvent(event: ClipboardEvent) {
    event.preventDefault();
  }
}