import {
    Component,
    OnInit,
    Renderer2,
    OnDestroy,
    HostBinding
} from '@angular/core';
import {FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import {AppService} from '@services/app.service';
import { LoaderService } from '@modules/shared/services/loader.service';
import { AlertService } from '@modules/shared/services/alert.service';
import { RegistrationService } from '@services/registration.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'container-fluid';

    public registerForm: FormGroup;
    public passwordHide : boolean = true;
    public confirmPasswordHide : boolean = true;

    constructor(
        private renderer: Renderer2,
        private appService: AppService,
        private _loaderService: LoaderService,
        private _alertService: AlertService,
		private registrationService: RegistrationService,
    ) {}

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'register-page'
        );
        this.registerForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, Validators.email]),
            password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
            confirmPassword: new FormControl(null, [Validators.required]),
        }, { validators: confirmPasswordValidator });
    }

  /**
   * [signUp USER REGITRATION]
   */
    signUp() {
        this._loaderService.showLoader();
    
        const params:{} = this.registerForm.value;
        // this.registrationService.userRegistration(params).subscribe(
        // (res:any)=>{
        //     console.log(res);
        //     if (res.status == 'success') {
        //         this._alertService.showAlert(res.message, 'success');
        //         this._loaderService.hideLoader();
        //         this.registerForm.reset();
        //     } else {
        //         this._loaderService.hideLoader();
        //         this._alertService.showAlert(res.message, 'error');
        //     }
        // });
    }

    togglemyPasswordFieldType(type:string) {
        if (type == 'password') {
            this.passwordHide = !this.passwordHide;
        } else {
            this.confirmPasswordHide = !this.confirmPasswordHide;
        }
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'register-page'
        );
    }
}

export const confirmPasswordValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    return control.value.password === control.value.confirmPassword
      ? null
      : { PasswordNoMatch: true };
  };