<section class="content">
    <div class="container-fluid pt-2">
        <div class="custom-table-design headingTop">

            <div class="row justify-content-between align-items-center mb-3">
                <div class="col-md-6">
                    <h2 class="heading-font m-0">Coupon Code Creation</h2>
                </div>
                <div class="col-md-6 d-flex justify-content-end">
                    <button class="btn btn-primary custom-btn" (click)="resetForm()"  data-bs-toggle="modal" data-bs-target="#addPromoModal"
                         >Add a Promo Code</button>
                </div>
            </div>

            <!-- custom search section -->

            <div class=" searchArea">
                <div class="srchCont">

                    <div class="searchBox">

                        <label>Search Text</label>

                        <input class="form-control" type="text"  #searchInput  [(ngModel)]="searchText" placeholder="Search" placeholder="Search Text">

                    </div>

                    <div class="srchbtnsDv">
                        <button class="btn btn-primary custom-btn reset" (click)="resetSearch()">Reset</button>
                        <button class="btn btn-primary custom-btn"  (click)="onSearchChange(searchInput.value)">Search</button>
                        <button class="btn btn-primary custom-btn dwnld" (click)="downloadCSV()" >Download CSV File</button>
                    </div>
                </div>

            </div>
   


            <div class="">
                <div class=" table-responsive p-0">
                 <!-- <table  datatable [dtTrigger]="dtTrigger"  [dtOptions]="dtOptions"
                    class="table table-hover text-nowrap">-->
                    <table   
                        class="table   text-nowrap">
                        <thead>
                            <tr>
                                <th class="stickyCol">Coupon Title</th>
                                <th>Coupon Code</th>
                                <th>Package</th>
                                <th>Discount</th>
                                <th>Maximum Uses</th>
                                <th>Minimum Uses</th>
                                <th>Validity</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="totalItems > 0; else noRecords">
                            <tr *ngFor="let item of promoList; let i = index">

                                <td>{{item?.coupon_name || 'NA'}}</td>
                                <td>{{item?.coupon_code}}</td>
                                <td>{{item?.package_name || 'NA'}}</td>
                                <td>{{item?.percentage_off || '0'}}%</td>
                                <td>{{item?.max_limit}}</td>
                             
                                <td>{{item?.min_limit || 'NA'}}</td>
                                <td>{{item?.validate_to | date}}</td>
                                <td  class="actionBlock text-center">
                                    <!-- <a class="fwl-icon-bg" tooltip="edit"
                                        (click)="openPromocodeModal(procodeModal, 'Edit', item)">
                                        <span class="view"><i class="fas fa-pen me-3"></i></span>
                                    </a>
                                    <a class="fwl-icon-bg" tooltip="delete" (click)="confirmPromocodeDeleteBox(item)">
                                        <span class="del"><i class="fas fa-trash-alt "></i></span>
                                    </a> -->
                                    <div class="dropdown">
                                        <div class=" dropdown-toggle" type="button" id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fas fa-ellipsis-v"></i>
                                        </div>
                                        <ul class="dropdown-menu "
                                            aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="fwl-icon-bg" tooltip="delete"
                                                    (click)="confirmPromocodeDeleteBox(item)">
                                                    <span class="del"><i class="fas fa-trash-alt "></i></span>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            </ng-container>
                            <ng-template #noRecords>
                                <tr>
                                  <td colspan="8" style="border-bottom: 0;" class="text-center ">No Records Found</td>
                                </tr>
                              </ng-template>
                        </tbody>
                     
                    </table>
                  
                    <nav aria-label="Page navigation" *ngIf="totalPages > 1;">
                        <ul class="pagination">
                        
                          <li class="page-item" [class.disabled]="currentPage === 1">
                            <a class="page-link"  (click)="pageChanged(currentPage - 1)">Previous</a>
                          </li>
                          <li class="page-item" *ngFor="let page of getPages()" [class.active]="page === currentPage">
                            <a class="page-link"  (click)="pageChanged(page)">{{ page }}</a>
                          </li>
                          <li class="page-item" [class.disabled]="currentPage === getTotalPages()">
                            <a class="page-link"  (click)="pageChanged(currentPage + 1)">Next</a>
                          </li>
                    
                        </ul>
                      </nav>
                </div>
            </div>


        </div>
    </div>
</section>

 



 
<!-- ********** add Promo Modal Modal ********* -->

<!-- Modal -->
<div class="modal fade" id="addPromoModal" data-bs-backdrop="static" tabindex="-1" aria-hidden="true"  data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content" >
            <div class="modal-header" >
                <h5 class="modal-title" id="exampleModalLabel1">Add Coupon</h5>
                <button type="button"  id="addPromocodeModalClose" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
           
            <div class="modal-body"  >
                <form autocomplete="false" name="promocodeForm" method="post" id="promocodeForm" [formGroup]="promocodeForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group position-relative">
                                <label for="promo-title" class="col-form-label">Device Type
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                             
                                <select class="form-select form-control"  [(ngModel)]="selectedDevice"  formControlName="device_type" #device_type  (change)="onChange(device_type.value)">
                                    <option  hidden value="" [disabled]="true" [selected]="true">Select </option>
                                    <option value="ios" selected>IOS </option>
                                    <option value="ANDROID" >ANDROID </option>
                                </select>
                                <div *ngIf="promocodeForm.controls['device_type'].invalid && (promocodeForm.controls['device_type'].dirty || promocodeForm.controls['device_type'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    Please Enter Device Type
                                </div>
                               
                            </div>
                        </div>
            
                        <!-- <div class="col-md-6">
                            <div class="form-group">
                                <label for="promo-code" class="col-form-label">Promo Code
                                    <span class="required-fields">*</span>
                                </label>
                                <input type="text" class="form-control" formControlName="code">
                                <div class="alert alert-danger"
                                    *ngIf="promocodeForm.get('code')?.invalid && promocodeForm.get('code')?.errors!=null && (promocodeForm.get('code')?.dirty || promocodeForm.get('code')?.touched)">
                                    <div *ngIf="promocodeForm.get('code')?.hasError('required')">
                                        This field is required
                                    </div>
                                </div>
                            </div>
                        </div> -->
            
                        <div class="col-md-6">
                            <div class="form-group position-relative">
                                <label for="package" class="col-form-label">Choose Package
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <!-- <select id="package_id" class="form-select form-control" formControlName="package_id">
                                    <option value="">select </option>
                                    <option *ngFor="let package of packageList;" [value]="package?.id">
                                        {{package?.package_name}}
                                    </option>
                                </select> -->
                                <select [(ngModel)]="selectedValue"  class="form-select form-control" formControlName="package_id"   (change)="onChangePakage(selectedValue)">
 
                                    <option  hidden value="" [disabled]="true" [selected]="true" >Select </option>
                                     <option *ngFor="let item of selectedData" [ngValue]="item.id">{{ item?.name }}</option>
                                </select>
                                <div *ngIf="promocodeForm.controls['package_id'].invalid && (promocodeForm.controls['package_id'].dirty || promocodeForm.controls['package_id'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    Please Enter Package Name
                                </div>
                               
                            </div>
                        </div>
            
                        <div class="col-md-6">
                            <div class="form-group position-relative">
                                <label   class="col-form-label">Discount Amount
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                            
                                <select [(ngModel)]="selecteDiscountPrice"  class="form-select form-control"  formControlName="discount_package_id">
                                    <option hidden value="" [disabled]="true" [selected]="true">Select </option>
                                    <option *ngFor="let item of selectedDataForDiscount" [ngValue]="item.id">{{ item?.discount_name }}</option>
                                </select>
                                <div *ngIf="promocodeForm.controls['discount_package_id'].invalid && (promocodeForm.controls['discount_package_id'].dirty || promocodeForm.controls['discount_package_id'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    Please Enter Discount
                                </div>
                               
                            </div>
                        </div>
            
                        <div class="col-md-6">
                            <div class="form-group position-relative">
                                <label for="discount" class="col-form-label">Coupon Title
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <input type="text" class="form-control"  formControlName="coupon_name" placeholder="Coupon Title">
                                <div *ngIf="promocodeForm.controls['coupon_name'].invalid && (promocodeForm.controls['coupon_name'].dirty || promocodeForm.controls['coupon_name'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    Please Enter Coupon Title
                                </div>
                            </div>
                        </div>
            
                        <div class="col-md-6">
                            <div class="form-group position-relative">
                                <label for="discount" class="col-form-label">Maximum Uses
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <input type="number" class="form-control" formControlName="maximum_uses" placeholder="Maximum Uses">
                                <div *ngIf="promocodeForm.controls['maximum_uses'].invalid && (promocodeForm.controls['maximum_uses'].dirty || promocodeForm.controls['maximum_uses'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    Please Enter Maximum Uses
                                </div>
                            </div>
                        </div>
            
                        <div class="col-md-6">
                            <div class="form-group position-relative">
                                <label for="validity" class="col-form-label">Validity
                                    <!-- <span class="required-fields">*</span> -->
                                </label>
                                <input type="text" [minDate]="minDate"  class="form-control" formControlName="valid_to" placeholder="dd/mm/yyyy"
                                    placement="top" bsDatepicker [bsConfig]="bsConfig" appDisableTyping>
                                    <div *ngIf="promocodeForm.controls['valid_to'].invalid && (promocodeForm.controls['valid_to'].dirty || promocodeForm.controls['valid_to'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                        Please Enter validity
                                    </div>
                            </div>
                        </div>
 <!--
                         <div class="col-md-6">
                            <div class="form-group position-relative">
                                <label for="coupon_code" class="col-form-label">coupon_code
                                   
                                </label>
                                <input type="text" class="form-control" formControlName="coupon_code" placeholder="coupon code">
                                <div *ngIf="promocodeForm.controls['coupon_code'].invalid && (promocodeForm.controls['coupon_code'].dirty || promocodeForm.controls['coupon_code'].touched)" class="invalid-tooltip d-block" style="right: 0;left: inherit;">
                                    Please Enter Maximum Uses
                                </div>
                            </div>
                        </div>-->
                    </div>
                </form>
            </div>

            <div class="modal-footer mt-3">
                <button type="button" class="btn btn-cancel"  data-bs-dismiss="modal" aria-label="Close" style="width: 180px;" #addPromocodeModalClose >Cancel</button>
                
                <button type="button" class="btn btn-primary"  style="width: 180px;"  (click)="submitAddPromocodeForm()" [disabled]="promocodeForm.invalid" >Save</button>
                   
                 
            </div>

        </div>
      
    </div>
</div>
<!-- ********** add Video Modal end********* -->
