import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertService } from '@modules/shared/services/alert.service';
import { CommonService } from '@modules/shared/services/common.service';
import { LoaderService } from '@modules/shared/services/loader.service';
import { SubscriptionsService } from '../_services/subscriptions.service';
import { DataTableDirective } from 'angular-datatables';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

import { CsvService } from '@services/csv.service';
import { Config } from 'datatables.net';
 

@Component({
  selector: 'app-subscriptions-list',
  templateUrl: './subscriptions-list.component.html',
  styleUrl: './subscriptions-list.component.scss'
})
export class SubscriptionsListComponent implements OnInit, AfterViewInit, OnDestroy {

  public subscriptionsList: any = [];
  public subscriptionsListAllData: any = [];
  public userData: any;
  public subscriptionDetails: any;
  /*** custom search* */
  filteredData:any;
  //timeFrame:string = '';
  bsConfig: any = {};
  startDate:any;
  searchText: string = '';
  selectTimeFrame:any = '';
  timeFrame: string[] = ['per month', 'per 2 months', 'per 3 months'];
  /* DataTable */
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtOptions:Config = {};
  dtTrigger: Subject<any> = new Subject();
  /* dt Ends */

  public modalType: string = "";
  /* ModelConfig */
  modalRef: BsModalRef;
  config = {
    animated: true,
    backdrop: true,
    keyboard: false,
    ignoreBackdropClick: true
  };
  /* ModelConfig Ends*/

  public packageList: any = [
		{ package_name: 'Gold Plan', id: '1' },
		{ package_name: 'Silver Plan', id: '2' },
		{ package_name: 'Platinum Plan', id: '3' },
		{ package_name: 'Bronze Plan', id: '4' }
	];

  constructor(
    private _commonService: CommonService,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _subscriptions: SubscriptionsService,
    private modalService: BsModalService,
    private _datePipe: DatePipe,
    private _csvService:CsvService
  ) { }

  ngOnInit(): void {
    this.bsConfig = Object.assign({}, {
			containerClass: 'theme-red',
			showWeekNumbers: false,
			dateInputFormat: 'MM-DD-YYYY',
			isAnimated: true
		});
    setTimeout(() => {
      this._loaderService.hideLoader();
    }, 100);

    this.userData = this._commonService.getItem('user_data');
    //this.userData = JSON.parse(this.userData);
    //console.log(this.userData);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      paging: false,
      searching: false,
      processing: false,
      dom: 'lrtip'
    };

    this.getSubscriptionList();
  }
  ngAfterViewInit(): void {
 
  }
  /**
   * Method         : getSubscriptionList
   * Description    : fetch list of existing Subscriptionss
   */
  public getSubscriptionList() {
    this._loaderService.showLoader();

    // this._promo.getAllPromoCodeList().subscribe({
    //   next: (resp: any) => {
    //     if (resp.status) {
    //       this.couponList = [...resp.data];
    //       this.dtTrigger.next(1);
    //       this._loaderService.hideLoader();
    //     } else {
    //       this._loaderService.hideLoader();
    //     }
    //   },
    //   error: (error: any) => {
    //     this._loaderService.hideLoader();
    //     console.error(error);
    //   }
    // });

    let subscriptions = [
      {
        "id": 1,
        "title": "Gold Plan",
        "started_date": "04-01-2024",
        "timeframe": "per month",
        "price": "15",
    
     
      },
      {
        "id": 2,
        "title": "Silver Plan",
        "started_date": "04-01-2023",
        "timeframe": "per 2 months",
        "price": "40",
    
      },
      {
        "id": 3,
        "title": "Silver Plan",
        "started_date": "05-23-2023",
        "timeframe": "per 3 months",
        "price": "40",
    
      },
      {
        "id": 4,
        "title": "Platinum Plan",
        "started_date": "05-23-2023",
        "timeframe": "per 3 months",
        "price": "75",
       
        
      }
     
    ];
    setTimeout(() => {
      this.subscriptionsListAllData = [...subscriptions];
     // this.filteredData = this.subscriptionsListData
     // console.log(this.searchFilter())
      this.subscriptionsList = this.subscriptionsListAllData
      this.dtTrigger.next('1');
      this._loaderService.hideLoader();
    }, 1000);
  }

  /**
   * Method         : openSubscriptionsModal
   * Description    : Open Model For add Subscriptions
   */
  public openSubscriptionsModal(subscriptionModal: TemplateRef<any>, type, subscriptionData) {
    this.modalType = type;
    this.subscriptionDetails = subscriptionData;
    this.modalRef = this.modalService.show(subscriptionModal, Object.assign({}, this.config, { class: 'modal-dialog modal-lg modal-dialog-centered' }));
  }
  
  /**
   * Method         : addSubscriptionsWithDetails
   * Description    : Add new Subscriptions event trigered from child component
   */
  public addSubscriptionWithDetails(event) {
    this._loaderService.showLoader();
    this._subscriptions.addSubscriptions(event).subscribe({
      next: (resp: any) => {
        console.log(resp);
        if (resp.status) {
          this.modalRef.hide();
          this.rerender();
          this._loaderService.hideLoader();
          this._alertService.showAlert('New Subscriptions Added Sucessfully', 'success');
        } else {
          this._loaderService.hideLoader();
          this._alertService.showAlert(resp.message, 'error');
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        console.error(error);
      }
    });
  }
  
  /**
   * Method         : editSubscriptionsWithDetails
   * Description    : Edit Subscriptions event trigered from child component
   */
  public editSubscriptionWithDetails(event) {
    this._subscriptions.updateSubscriptions(event).subscribe({
      next: (resp: any) => {
        console.log(resp);
        if (resp.status) {
          this.modalRef.hide();
          this.rerender();
          this._loaderService.hideLoader();
          this._alertService.showAlert('Subscriptions Details Updated Sucessfully', 'success');
        } else {
          this._loaderService.hideLoader();
          this._alertService.showAlert(resp.message, 'error');
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        console.error(error);
      }
    });
  }
  
   /**
   * Method         : confirmSubscriptionsDeleteBox
   * Description    : takes use's permission before deleting any existing Subscriptions
   */
   confirmSubscriptionsDeleteBox(subscriptions: any) {
    let params = {
      id: subscriptions.id
    };

    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this user record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._subscriptions.deleteSubscriptions(params).subscribe(
          (resp: any) => {
            console.log(resp);
            if (resp.status) {
              Swal.fire(
                'Deleted!',
                'Subscriptions record has been deleted.',
                'success'
              )
              this.rerender();
            }
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Subscriptions record is not deleted :)',
          'error'
        )
      }
    })
  }



  applyFilters(){
   this.startDate =  this._datePipe.transform(this.startDate, 'MM-dd-yyyy');
   console.log(this.startDate )
   console.log(this.subscriptionsListAllData);
    this.filteredData = this.subscriptionsListAllData.filter(item =>
      (this.searchText ? item.title.toLowerCase().includes(this.searchText.toLowerCase()) : true) &&
      (this.selectTimeFrame ? item.timeframe === this.selectTimeFrame : true) // Filter by selected timeFrame
       &&
      (this.startDate ? this._datePipe.transform(item.started_date,  'MM-dd-yyyy' ) == this.startDate : true)
    );
    console.log(this.filteredData)
     this.rerenderAfterSearch();
  }

  public rerenderAfterSearch(): void {
    this.dtElement.dtInstance.then((dtInstance) => {
      // Destroy the table first
      this._loaderService.showLoader();
      dtInstance.destroy(); 
      this.subscriptionsList = this.filteredData;
      this.dtTrigger.next('1');
      this._loaderService.hideLoader();
   
    });
  }
 
public rerender(): void {
  this.dtElement.dtInstance.then((dtInstance) => {
    // Destroy the table first
    dtInstance.destroy();
    this.getSubscriptionList();
  });
}
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  downloadCSV(){
    // Get DataTables instance
    this._csvService.downloadCsv(this.subscriptionsList, 'data.csv');
  
  }
}
