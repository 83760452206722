import { Component, OnInit, Input } from '@angular/core';

declare var google: any;

@Component({
  selector: 'app-piechart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})
export class PiechartComponent implements OnInit {

  private options;
  private data;
  private chart;

  @Input() idName;
  @Input() dataValue;
  @Input() type;
  @Input() rawColorArray;

  constructor() { }

  ngOnInit() {
    /* =====Chart Init===== */
    google.charts.load('current', {
      'packages': ['corechart']
    });
    this.start();
  }

  /**
   * start
   */
  public start() {
    google.charts.setOnLoadCallback(() => this.drawGraph());
  }

  /**
   * drawGraph
   */
  public drawGraph() {
    const rawData = [...this.dataValue];

    if (this.type == 'population') {
      rawData.unshift(['City', 'Population']);
      this.data = new google.visualization.arrayToDataTable(rawData);
    } else if (this.type == 'browser') {
      rawData.unshift(['Browser', 'Percentage']);
      this.data = new google.visualization.arrayToDataTable(rawData);
    } else {
      this.data = new google.visualization.DataTable();
      this.data.addColumn('string', 'Language');
      this.data.addColumn('number', 'Speakers (in millions)');
      this.data.addRows(rawData);
    }

    if (this.type == 'population') {
      this.options = {
        title: "Population of Largest U.S. Cities",
        is3D: true,
        chartArea: { left: 0, top: 0, width: '100%', height: '200' },
        legend: { position: 'bottom', alignment: 'center' },
        sliceVisibilityThreshold: 0.0000001,
        //tooltip: { trigger: 'selection' },
        //colors: this.ColorArray(this.rawColorArray),
      };
    } else if (this.type == 'browser') {
      this.options = {
        title: 'Browser market shares at a specific website, 2014',
        pieHole: 0.4,
        chartArea: { left: 0, top: 10, width: '100%', height: '80%' },
        legend: { position: 'right', alignment: 'center' },
        sliceVisibilityThreshold: 0.0000001,
      };
    } else if (this.type == 'language') {
      this.options = {
        title: 'Indian Language Use',
        is3D: true,
        chartArea: { left: 0, top: 10, width: '100%', height: '100%' },
        legend: { position: 'right', alignment: 'center' },
        sliceVisibilityThreshold: 0.0000001,
        pieSliceText: 'label',
        slices: {
          //1: { color: 'transparent' }, //To Removing Slices
          4: { offset: 0.2, color: 'yellow' }, //Add Color to specific Slices
          12: { offset: 0.3 },
          14: { offset: 0.4 },
          15: { offset: 0.5 },
        },
      };
    } else {
      this.options = {
        title: '',
        is3D: true,
        chartArea: { left: 0, top: 0, width: '100%', height: '90%' },
        legend: { position: 'bottom', alignment: 'center' },
        sliceVisibilityThreshold: 0.0000001,
        tooltip: { textStyle: { color: '#000' }, showColorCode: true }
      };
    }

    this.chart = new google.visualization.PieChart(document.getElementById(this.idName));
    this.chart.draw(this.data, this.options);

  }

  /**
   * ColorArray
   */
  public ColorArray(item): [] {
    let itemSplit = item.split(',');
    return itemSplit;
  }

}
