import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { APIConstant } from '@/constant/APIConstant';

@Injectable({
	providedIn: 'root'
})
export class ProfileSettingsService {

	private APIEndpoint = environment.baseUrl;

	constructor(private http: HttpClient) { }	

	public editProfile(params:any){
		return this.http.post(`${this.APIEndpoint+APIConstant.profile.editProfileDetails}`, params);
	}

	public getProfileData(params:any){
		return this.http.post(`${this.APIEndpoint+APIConstant.profile.getProfileDetails}`, params);
	}

	public uploadProfilePicture(params:any){
		return this.http.post(`${this.APIEndpoint}/upload-profile-picture`, params)
		.pipe(
			map((response:any) => {
				console.log(response);
				let responseAny:any = response;
				return responseAny;
			})
		);
	}
}
