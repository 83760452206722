import { Component, OnInit, Input, OnDestroy } from '@angular/core';

declare let google: any;

@Component({
  selector: 'app-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['./column-chart.component.css']
})
export class ColumnChartComponent implements OnInit {

  @Input() idName;
  @Input() dataValue;
  @Input() type;
  @Input() rawColorArray;

  constructor() { }

  ngOnInit() {
    /* =====Chart Init===== */
    google.charts.load('current', {
      'packages': ['corechart']
    });
    this.start();
  }

  /**
   * start
   */
  public start() {
    google.charts.setOnLoadCallback(() => this.drawGraph());
  }

  /**
   * drawGraph
   */
  public drawGraph() {
    const rawData = [...this.dataValue];
    rawData.map((elm, i) => {
      if (this.type == 'population') {
        elm.push('#0072A7');
      } else {
        elm.push('#1f8ceb');
      }
    });

    let chartTitle = "";
    let vAxisTitle = "";
    let hAxisTitle = "";
    let custHeight = "100%";

    if (this.type == 'population') {
      rawData.unshift(['City', 'Population', { role: "style" }]);
      chartTitle = "Population of Largest U.S. Cities";
      hAxisTitle = "City";
      vAxisTitle = "Total Population";
      custHeight = "100%"
    }
    
    const data = new google.visualization.arrayToDataTable(rawData);
    let view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation"
      },
      2]);
    let options = {
      title: chartTitle,
      width: "100%",
      height: custHeight,
      chartArea: { width: '70%' },
      bar: { groupWidth: "95%" },
      legend: { position: "none" },
      vAxis: {
        title: vAxisTitle,
        minValue: 0
      },
      hAxis: {
        title: hAxisTitle
      },
      tooltip: { textStyle: { color: '#000' }, showColorCode: true}
    };

    let chart = new google.visualization.ColumnChart(document.getElementById(this.idName));
    chart.draw(view, options);

  }

  ngOnDestroy(): void {
    this.dataValue = [];
  }

}
