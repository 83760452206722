import { Component, OnInit } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, FormGroup, FormArray, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { CommonService } from "@modules/shared/services/common.service";
import { LoaderService } from "@modules/shared/services/loader.service";
import { ProfileSettingsService } from '@services/profile-settings.service';
import { AlertService } from '@modules/shared/services/alert.service';
import { JsonData } from '@/constant/JsonData';

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
	public warningMsgFleUpload: string = '';
	public successMsgFleUpload: string = '';
	public files: any = [];
	public profileEditForm!: FormGroup;
	public profileData: any = {}
	public showProfileEditForm: boolean = false;

	bsConfig: any = {};

	public countryList: any = JsonData.profile.countryList;

	public stateList: any = JsonData.profile.stateList;

	public genderList: any = JsonData.profile.genderList;

	public languageList: any = JsonData.profile.languageList;

	public nationalityList: any = JsonData.profile.nationalityList;

	public MsList: any = JsonData.profile.MsList;

	constructor(
		private _loaderService: LoaderService,
		private _profileEditForm: FormBuilder,
		private _datePipe: DatePipe,
		private _commonService: CommonService,
		private _alertService: AlertService,
		private _profileSettingsService: ProfileSettingsService
	) { }

	ngOnInit(): void {
		this.profileData = this._commonService.getItem('user_data');
		console.log(this.profileData);

		this.fetchProfileData();
		this.initProfileEditForm();
	}

	onFileChange(event: any) {
		if (event.target.files.length > 0) {
			this.warningMsgFleUpload = '';
			const file = event.target.files[0];
			console.log(file);
			if (file.size > 5000000) {
				this.warningMsgFleUpload = "The file size should be of maximum 5 Mb.";
				return;
			} else {
				this.warningMsgFleUpload = "";
			}

			var mimeType = file.type;
			if (mimeType.match(/image\/*/) == null) {
				this.warningMsgFleUpload = "Only images are supported.";
				return;
			} else {
				this.warningMsgFleUpload = "";
			}

			if (this.warningMsgFleUpload == "") {
				var reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (_event) => {
					this.profileData.picture = reader.result;
				}

				this.uploadFile(file);
			}
		}
	}

	uploadFile(file: any) {
		/// upload a new document
		const formData = new FormData();
		formData.append('file', file);
		formData.append('email', this.profileData.email);
		console.log(formData);

		this._profileSettingsService.uploadProfilePicture(formData).subscribe(
			(uploadResp: any) => {
				console.log(uploadResp);
				if (uploadResp.status == 'success') {
					this.warningMsgFleUpload = '';
					this.successMsgFleUpload = 'Profile Picture Updated Sucessfully';
					// this.profileData.file_name = uploadResp.data.filename;
					this.profileData.picture = uploadResp.data.s3_url;
					let this1 = this;
					setTimeout(function () {
						this1.successMsgFleUpload = '';
					}, 3000);
				} else {
					this.warningMsgFleUpload = uploadResp.message;
					this.successMsgFleUpload = '';
				}
			});
	}

	public fetchProfileData() {
		this._loaderService.showLoader();

		let params = {
			email: this.profileData?.email
		}

		this._profileSettingsService.getProfileData(params).subscribe(
			(resp: any) => {
				console.log(resp);
				if (resp.status == 'success') {
					this.profileData = resp.body.profileData;
					if (this.profileData.dob == '0000-00-00') {
						this.profileData.dob = '';
					} else {
						this.profileData.dob = this._datePipe.transform(this.profileData.dob, 'MM-dd-yyyy');
					}
					if (this.profileData.nationality != null) {
						let selectedNationality = this.nationalityList.filter((data: any) => {
							return data.id == this.profileData.nationality;
						});
						this.profileData.nationality_name = selectedNationality[0]['nationality_name'];
					}

					if (this.profileData.language_known != null && this.profileData.language_known != '') {
						this.profileData.language_known = this.profileData.language_known.split(',')
					}

					if (this.profileData.country_id != null) {
						let selectedCountry = this.countryList.filter((data: any) => {
							return data.id == this.profileData.country_id;
						});
						this.profileData.country_name = selectedCountry[0]['country_name'];
					}

					if (this.profileData.state_id != null) {
						let selectedState = this.stateList.filter((data: any) => {
							return data.id == this.profileData.state_id;
						});
						this.profileData.state_name = selectedState[0]['state_name'];
					}

					console.log(this.profileData);
					this._alertService.showAlert(resp.message, 'success');
					this._loaderService.hideLoader();
				} else {
					this._alertService.showAlert(resp.message, 'error');
					this._loaderService.hideLoader();
				}
			}
		);
	}

	public openProfileEditForm() {
		//this.profileEditForm.reset();
		this.showProfileEditForm = true;
		this.bsConfig = Object.assign({}, {
			containerClass: 'theme-blue',
			showWeekNumbers: false,
			dateInputFormat: 'MM-DD-YYYY',
			isAnimated: true
		});
		this.initProfileEditForm();
	}

	public initProfileEditForm() {
		this.profileEditForm = this._profileEditForm.group({
			name: this._profileEditForm.control(this.profileData?.name, [Validators.required]),
			email: this._profileEditForm.control(this.profileData?.email, [Validators.required, Validators.email]),
			address: this._profileEditForm.control(this.profileData?.address, [Validators.required]),
			country_id: this._profileEditForm.control(this.profileData?.country_id, [Validators.required]),
			state_id: this._profileEditForm.control(this.profileData?.state_id, [Validators.required]),
			city: this._profileEditForm.control(this.profileData?.city, [Validators.required]),
			zip_code: this._profileEditForm.control(this.profileData?.zip_code, [Validators.required]),
			mobile_no: this._profileEditForm.control(this.profileData?.mobile_no, [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
			dob: this._profileEditForm.control(this.profileData?.dob, [Validators.required]),
			gender: this._profileEditForm.control(this.profileData?.gender, [Validators.required]),
			marital_status: this._profileEditForm.control(this.profileData?.marital_status, [Validators.required]),
			language_known: this._profileEditForm.control(this.profileData?.language_known, []),
			nationality: this._profileEditForm.control(this.profileData?.nationality, [Validators.required]),
		});
	}

	public submitProfileEditForm() {
		this._loaderService.showLoader();
		let formValue = this.profileEditForm.value;
		//console.log(formValue);
		let params = Object.assign(formValue);

		params.mobile_no = params.mobile_no.toString();
		params.dob = this._datePipe.transform(params.dob, 'yyyy-MM-dd');

		if (params.hasOwnProperty('language_known')) {
			if (params.language_known != null && params.language_known != '') {
				if (params.language_known.indexOf(',') == -1) {
					params.language_known = params.language_known.join(',');
				}
			}
		}
		//console.log(params);

		this._profileSettingsService.editProfile(params).subscribe(
			(resp: any) => {
				console.log(resp);
				if (resp.status == 'success') {
					this.profileData = resp.body.profileData;
					this.profileData.dob = this._datePipe.transform(params.dob, 'MM-dd-yyyy');
					this._alertService.showAlert(resp.message, 'success');
					this._loaderService.hideLoader();
				} else {
					this._alertService.showAlert(resp.message, 'error');
					this._loaderService.hideLoader();
				}
			}
		);
	}
}
