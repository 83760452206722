<div class="row">
    <div class="col-lg-7 log-reg-side-bg">
        <div class="cntntRightArea"><h3>It's time to put your</h3><h2>Peace of mind first</h2><p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on</p></div>
    </div>
    
    <div class="col-lg-5 box-align">
        <div class="login-box">
            <div class="">
                <div class="card-header text-center mb-4 border-0">
                    <a [routerLink]="['/']" class="h1"><img src="../../../assets/img/logo-main.png"></a>
                </div>
                <div class="card-body">
                    <!-- <p class="login-box-msg">Sign in to start your session</p> -->
            
                    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
                        <div class="mb-3">
                            <input
                                formControlName="email"
                                type="email"
                                class="form-control"
                                placeholder="Email"
                            />
                            <div *ngIf="forgotPasswordForm.controls['email'].invalid && (forgotPasswordForm.controls['email'].dirty || forgotPasswordForm.controls['email'].touched)"
                                class="alert alert-danger mt-2">
                                <div *ngIf="forgotPasswordForm.controls['email'].errors?.['required'] || forgotPasswordForm.controls['email'].errors?.['email']">
                                    Please enter valid email id
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3">
                                <button type="submit" class="btn btn-primary d-block w-100 green-bg" [disabled]="forgotPasswordForm.invalid">Request new password</button>
                            </div>
                            <div class="col-12 mb-3">
                                <button class="btn btn-primary d-block w-100"  [routerLink]="['/login']">Login</button>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- /.login-card-body -->
            </div>
        </div>
    </div>
</div>
