import { Component, OnInit, TemplateRef, ViewChild, OnDestroy } from "@angular/core";
import { AlertService } from "@modules/shared/services/alert.service";
import { CommonService } from "@modules/shared/services/common.service";
import { LoaderService } from "@modules/shared/services/loader.service";
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { AccountTypesService } from "../_services/account-types.service";
import { Config } from "datatables.net";

@Component({
  selector: 'app-account-types',
  templateUrl: './account-types.component.html',
  styleUrl: './account-types.component.scss'
})
export class AccountTypesComponent implements OnInit, OnDestroy {
  public accountTypeList: any = [];
  public userData: any;
  public accountTypeDetails: any;

  /* DataTable */
  @ViewChild(DataTableDirective, { static: false })
  dtElement: any;
  dtOptions: Config = {};
  dtTrigger: Subject<any> = new Subject();
  /* dt Ends */

  public modalType: string = "";
  /* ModelConfig */
  modalRef: BsModalRef;
  config = {
    animated: true,
    backdrop: true,
    keyboard: false,
    ignoreBackdropClick: true
  };
  /* ModelConfig Ends*/

  constructor(
    private _commonService: CommonService,
    private _alertService: AlertService,
    private _loaderService: LoaderService,
    private _accountTypesService: AccountTypesService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this._loaderService.hideLoader();
    }, 100);

    this.userData = this._commonService.getItem('user_data');
    //this.userData = JSON.parse(this.userData);
    //console.log(this.userData);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 2,
      paging: false,
      searching: false,
      processing: false
    };

    this.getAccountTypeList();
  }

  /**
   * Method         : getAccountTypeList
   * Description    : fetch list of existing account types
   */
  public getAccountTypeList() {
    this._loaderService.showLoader();

    // this._accountTypesService.getAllAccountTypeList().subscribe({
    //   next: (resp: any) => {
    //     if (resp.status) {
    //       this.accountTypeList = [...resp.data];
    //       this.dtTrigger.next(1);
    //       this._loaderService.hideLoader();
    //     } else {
    //       this._loaderService.hideLoader();
    //     }
    //   },
    //   error: (error: any) => {
    //     this._loaderService.hideLoader();
    //     console.error(error);
    //   }
    // });

    let types = [
      {
        "id": 1,
        "name": "Super Admin",
        "created_at": "2024-01-31T12:06:59.000000Z",
        "updated_at": "2024-01-31T12:06:59.000000Z"
      },
      {
        "id": 2,
        "name": "Admin",
        "created_at": "2024-01-31T12:34:12.000000Z",
        "updated_at": "2024-01-31T12:34:12.000000Z"
      },
      {
        "id": 3,
        "name": "Regional Manager",
        "created_at": "2024-02-19T10:41:43.000000Z",
        "updated_at": "2024-02-19T10:41:43.000000Z"
      },
      {
        "id": 4,
        "name": "Branch Manager",
        "created_at": "2024-02-23T11:50:30.000000Z",
        "updated_at": "2024-02-23T11:50:30.000000Z"
      },
      {
        "id": 5,
        "name": "User",
        "created_at": "2024-02-23T11:58:00.000000Z",
        "updated_at": "2024-02-23T11:58:00.000000Z"
      },
      {
        "id": 6,
        "name": "Developer",
        "created_at": "2024-02-28T12:44:50.000000Z",
        "updated_at": "2024-02-28T12:44:50.000000Z"
      }
    ];
    setTimeout(() => {
      this.accountTypeList = [...types];
      this.dtTrigger.next(1);
      this._loaderService.hideLoader();
    }, 1000);
  }

  /**
   * Method         : openAccountTypeModal
   * Description    : Open Model For add user
   */
  public openAccountTypeModal(accountTypeModal: TemplateRef<any>, type, accountTypeData) {
    this.modalType = type;
    this.accountTypeDetails = accountTypeData;
    this.modalRef = this.modalService.show(accountTypeModal, Object.assign({}, this.config, { class: 'modal-dialog modal-md modal-dialog-centered' }));
  }
  
  /**
   * Method         : addAccountTypeWithDetails
   * Description    : Add new account type event trigered from child component
   */
  public addAccountTypeWithDetails(event) {
    this._loaderService.showLoader();
    this._accountTypesService.addAccountType(event).subscribe({
      next: (resp: any) => {
        console.log(resp);
        if (resp.status) {
          this.modalRef.hide();
          this.rerender();
          this._loaderService.hideLoader();
          this._alertService.showAlert('New Account Type Added Sucessfully', 'success');
        } else {
          this._loaderService.hideLoader();
          this._alertService.showAlert(resp.message, 'error');
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        console.error(error);
      }
    });
  }
  
  /**
   * Method         : editAccountTypeWithDetails
   * Description    : Edit account type event trigered from child component
   */
  public editAccountTypeWithDetails(event) {
    this._accountTypesService.updateAccountType(event).subscribe({
      next: (resp: any) => {
        console.log(resp);
        if (resp.status) {
          this.modalRef.hide();
          this.rerender();
          this._loaderService.hideLoader();
          this._alertService.showAlert('Account Type Details Updated Sucessfully', 'success');
        } else {
          this._loaderService.hideLoader();
          this._alertService.showAlert(resp.message, 'error');
        }
      },
      error: (error: any) => {
        this._loaderService.hideLoader();
        console.error(error);
      }
    });
  }
  
   /**
   * Method         : confirmAccountTypeDeleteBox
   * Description    : takes use's permission before deleting any existing account type
   */
  confirmAccountTypeDeleteBox(accountType: any) {
    let params = {
      id: accountType.id
    };

    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this user record!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._accountTypesService.deleteAccountType(params).subscribe(
          (resp: any) => {
            console.log(resp);
            if (resp.status) {
              Swal.fire(
                'Deleted!',
                'Account type record has been deleted.',
                'success'
              )
              this.rerender();
            }
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Account type record is not deleted :)',
          'error'
        )
      }
    })
  }

  public rerender(): void {
    this.dtElement.dtInstance.then((dtInstance) => {
      // Destroy the table first
      dtInstance.destroy();
      this.getAccountTypeList();
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
