<div class="modal-header">
    <h5 class="modal-title pull-left">{{modalType}} Subscription</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form autocomplete="false" name="subscriptionForm" method="post" id="subscriptionForm" [formGroup]="subscriptionForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="promo-title" class="col-form-label">Subscription Title
                        <span class="required-fields">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="title">
                    <div class="alert alert-danger"
                        *ngIf="subscriptionForm.get('title')?.invalid && subscriptionForm.get('title')?.errors!=null && (subscriptionForm.get('title')?.dirty || subscriptionForm.get('title')?.touched)">
                        <div *ngIf="subscriptionForm.get('title')?.hasError('required')">
                            This field is required
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="promo-code" class="col-form-label">Promo Code
                        <span class="required-fields">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="code">
                    <div class="alert alert-danger"
                        *ngIf="subscriptionForm.get('code')?.invalid && subscriptionForm.get('code')?.errors!=null && (subscriptionForm.get('code')?.dirty || subscriptionForm.get('code')?.touched)">
                        <div *ngIf="subscriptionForm.get('code')?.hasError('required')">
                            This field is required
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="package" class="col-form-label">Package
                        <span class="required-fields">*</span>
                    </label>
                    <select id="package_id" class="form-select form-control" formControlName="package_id">
                        <option value="">select package</option>
                        <option *ngFor="let package of packageList;" [value]="package?.id">
                            {{package?.package_name}}
                        </option>
                    </select>
                    <div class="alert alert-danger"
                    *ngIf="subscriptionForm.get('package_id')?.invalid && subscriptionForm.get('package_id')?.errors!=null && (subscriptionForm.get('package_id')?.dirty || subscriptionForm.get('package_id')?.touched)">
                        <div *ngIf="subscriptionForm.get('package_id')?.hasError('required')">
                            This field is required.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="discount" class="col-form-label">Discount
                        <span class="required-fields">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="discount">
                    <div class="alert alert-danger"
                        *ngIf="subscriptionForm.get('discount')?.invalid && subscriptionForm.get('discount')?.errors!=null && (subscriptionForm.get('discount')?.dirty || subscriptionForm.get('discount')?.touched)">
                        <div *ngIf="subscriptionForm.get('discount')?.hasError('required')">
                            This field is required
                        </div>
                        <div
                            *ngIf="subscriptionForm.get('discount')?.hasError('max') || subscriptionForm.get('discount')?.hasError('pattern')">
                            This field should be a valid number with max value 100
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="discount" class="col-form-label">Maximum Uses
                        <span class="required-fields">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="maximum_uses">
                    <div class="alert alert-danger"
                        *ngIf="subscriptionForm.get('maximum_uses')?.invalid && subscriptionForm.get('maximum_uses')?.errors!=null && (subscriptionForm.get('maximum_uses')?.dirty || subscriptionForm.get('maximum_uses')?.touched)">
                        <div *ngIf="subscriptionForm.get('maximum_uses')?.hasError('required')">
                            This field is required
                        </div>
                        <div
                            *ngIf="subscriptionForm.get('maximum_uses')?.hasError('pattern')">
                            This field should be a valid number
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label for="validity" class="col-form-label">Validity
                        <span class="required-fields">*</span>
                    </label>
                    <input type="text" class="form-control" formControlName="validity" placeholder="Select Date" placement="top" readonly bsDatepicker [bsConfig]="bsConfig">
                    <div class="alert alert-danger"
                        *ngIf="subscriptionForm.get('validity')?.invalid && subscriptionForm.get('validity')?.errors!=null && (subscriptionForm.get('validity')?.dirty || subscriptionForm.get('validity')?.touched)">
                        <div *ngIf="subscriptionForm.get('validity')?.hasError('required')">
                            This field is required
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="submitAddSubscriptionForm()" [disabled]="subscriptionForm.invalid"
        *ngIf="modalType=='Add'">Save</button>
    <button type="button" class="btn btn-primary" (click)="submitEditSubscriptionForm()" [disabled]="subscriptionForm.invalid"
        *ngIf="modalType=='Edit'">Update</button>
</div>