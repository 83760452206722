import { Component, OnInit, Input, OnDestroy } from '@angular/core';

declare var google: any;

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit, OnDestroy {

  @Input() idName;
  @Input() dataValue;
  @Input() type;
  @Input() rawColorArray;

  constructor() { }

  ngOnInit() {
    /* =====Chart Init===== */
    google.charts.load('current', {
      'packages': ['corechart']
    });
    this.start();
  }

  /**
   * start
   */
  public start() {
    google.charts.setOnLoadCallback(() => this.drawGraph());
  }

  /**
   * drawGraph
   */
  public drawGraph() {
    const rawData = [...this.dataValue];
    rawData.map((elm, i) => {
      if (this.type == 'population') {
        elm.push('#0072A7');
      } else {
        elm.push('#1f8ceb');
      }
    });

    let chartTitle = "";
    let vAxisTitle = "";
    let hAxisTitle = "";
    let custHeight;

    if (this.type == 'population') {
      rawData.unshift(['City', 'Population', { role: "style" }]);
      chartTitle = "Population of Largest U.S. Cities";
      hAxisTitle = "Total Population";
      vAxisTitle = 'City';
      custHeight = "100%"
    }

    const data = new google.visualization.arrayToDataTable(rawData);
    let view = new google.visualization.DataView(data);
    view.setColumns([0, 1,
      {
        calc: "stringify",
        sourceColumn: 1,
        type: "string",
        role: "annotation"
      },
      2]);

    let options = {
      title: chartTitle,
      width: "100%",
      height: custHeight,
      bar: { groupWidth: "95%" },
      chartArea: { width: '70%' },
      legend: { position: "none" },
      vAxis: {
        title: vAxisTitle,
        minValue: 0
      },
      hAxis: {
        title: hAxisTitle
      },
      tooltip: { textStyle: { color: '#000' }, showColorCode: true, }
    };

    let chart = new google.visualization.BarChart(document.getElementById(this.idName));
    chart.draw(view, options);
  }

  ngOnDestroy(): void {
    this.dataValue = [];
  }
}
