<div class="row">
    <div class="col-lg-6 log-reg-side-bg d-flex align-items-center justify-content-center">
       
            <img src="../../../assets/img/logo.png" alt="">
       
    </div>
    <div class="col-lg-6 box-align">
        <div class="login-box">
            <div class="">
                <div class="authHead text-center mb-4 border-0">
                   <h2>Login</h2>
                   <p>You are logging in as an Admin</p>
                </div>
                <div class="card-body">            
                    <form [formGroup]="loginForm" (ngSubmit)="signIn()" autocomplete="off">
                        <div class="mb-3">
                            <label class="mb-1">Email Address</label>
                            <input
                                formControlName="email"
                                type="email"
                                class="form-control"
                                placeholder="email@example.com"
                            />
                            <div *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)"
                                class="alert alert-danger mt-2">
                                <div *ngIf="loginForm.controls['email'].errors?.['required'] || loginForm.controls['email'].errors?.['email']">
                                    Please enter valid email id
                                </div>
                            </div>
                        </div>
                        
                        <div class="mb-3">
                            <label class="mb-1">Password</label>
                            <input
                                formControlName="password"
                                type="password"
                                class="form-control"
                                placeholder="Password"
                            />
                            <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)"
                                class="alert alert-danger mt-2">
                                <div *ngIf="loginForm.controls['password'].errors?.['required']">
                                    your password
                                </div>
                            </div>
                        </div>
                    
                        <div class="row">
                         
                             
                            <div class="col-12">
                                <div   class="alert alert-danger" *ngIf="errorLoginCredential">
                                
                                    {{errorLoginCredential}}
                                
                             

                            </div>
                                <button type="submit" class="btn btn-primary d-block mt-3" [disabled]="loginForm.invalid">Sign In</button>
                            </div>
                            <!-- <div class="col-12 mb-3">
                                <button class="btn btn-primary d-block w-100"  [routerLink]="['/register']"> Register a new membership</button>                            
                            </div> -->
                        </div>

                   

                       
                    </form>
                </div>
                <!-- /.login-card-body -->
            </div>
        </div>
    </div>
</div>





