import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit  } from '@angular/core';
import { AlertService } from "@modules/shared/services/alert.service";
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { CommonService } from '@modules/shared/services/common.service';
import { AuthService } from '@services/auth.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public showMiniLogoIcon: boolean = false;
    public onMouseOver: boolean = false;
    public user;
    public menu = MENU;
    savedMenu = this.menu;
    public accessControl;
 





    constructor(
        private store: Store<AppState>,
        private commonService: CommonService,
        private router: Router, 
        private _authService: AuthService,
        private _alertService: AlertService,
    ) {
        this._authService.userAccess = new Subject();
        this._authService.userAccess.subscribe((res) => {
            this.setSideMenu(res);
        })

       

   
       
    }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
            if (state.screenSize >= 768) {
                this.showMiniLogoIcon = state.menuSidebarCollapsed;
            }
        });
        this.user = this.commonService.getItem('user_data');
        //console.log(this.user);
        this.setSideMenu();
    }

    mouseEnterOnMenuSidebar() {
        if (this.showMiniLogoIcon) {
            this.onMouseOver = true;
        }
    }

    mouseLeaveFromMenuSidebar() {
        if (this.showMiniLogoIcon) {
            this.onMouseOver = false;
        }
    }

    setSideMenu(data?) {
        this.menu = this.savedMenu;
        if (data) {
            this.accessControl = data;
        } else {
            this.accessControl = JSON.parse(localStorage.getItem('user_access'));
        }
        //console.log(this.accessControl);

        // this.accessControl.forEach(element => {
        //     element.sub_menu.forEach((sub) => {
        //         if (sub.name.toLowerCase() == 'view')
        //             this.menu.forEach((item) => {
        //                 if (item.permission_name.toLowerCase() == 'na') {
        //                     item.permission = true;
        //                 }
        //                 // if (item.children) {
        //                 //     item.children.forEach((child) => {
        //                 //         if (child.permission_name.toLowerCase() == 'na') {
        //                 //             child.permission = true;
        //                 //         }
        //                 //         else if (child.permission_name.toLowerCase() == element.name.toLowerCase()) {
        //                 //             child.permission = true;
        //                 //             item.permission = true;
        //                 //         }
        //                 //     })
        //                 // } else if (item.permission_name.toLowerCase() == element.name.toLowerCase()) {
        //                 //     item.permission = true;
        //                 // }
        //             })
        //     })

        // })
    };

    logout() {
        //this.appService.logout();

console.log(this.getToken())

        Swal.fire({
            title: 'Are you sure want to Logout',
            text: '',
            // icon: 'warning',
            showCancelButton: true,
            showCloseButton: false,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then((result) => {
            console.log("logout");
            this.router.navigate(['/login']);
            if (result.value) {
               
                this._authService.signOut(this.getToken()).subscribe({
                    next: (resp: any) => {
                      console.log(resp);
                      if (resp.status) {
                        localStorage.removeItem('access_token');
                        this.router.navigate(['/login']);
                        this._alertService.showAlert(resp.message, 'success');
                      
                     } 
                     else{
                       
                       
                     }
                      },
                      error: (error: any) => {
                        localStorage.removeItem('access_token');
       // console.error(error);
                      }
                    });
            }
          })


      
    }


    getToken(){
		return localStorage.getItem('access_token');
	}

   
}

export const MENU = [
    // {
    //     name: 'Dashboard',
    //     iconClasses: 'pe-7s-home',
    //     path: ['/'],
    //     permission: true,
    //     permission_name: 'na'
    // },
    // {
    //     name: 'Blank',
    //     iconClasses: 'pe-7s-wallet',
    //     path: ['/blank'],
    //     permission: true,
    //     permission_name: 'na'
    // },
 
    // {
    //     name: 'Users',
    //     iconClasses: 'pe-7s-users',
    //     path: ['/users/list'],
    //     permission: false,
    //     permission_name: 'users',
    // },
    // {
    //     name: 'Account Types',
    //     iconClasses: 'pe-7s-note2',
    //     path: ['/account-types'],
    //     permission: false,
    //     permission_name: 'na',
    // },
    // {
    //     name: 'Access Group',
    //     iconClasses: 'pe-7s-settings',
    //     path: ['/access-group'],
    //     permission: false,
    //     permission_name: 'access group',
    // },
    // {
    //     name: 'Coupon',
    //     iconClasses: 'pe-7s-albums',
    //     path: ['/coupon'],
    //     permission: false,
    //     permission_name: 'coupon',
    // },

    
];
