export const JsonData = {
    user: {
        userList: [
            {
                "id": 1,
                "name": "Mantu Show",
                "email": "mantu.show@appsbee.com",
                "phone_number": "9606281213",
                "city": "Kolkata",
                "status": "Verified",
                "email_verified_at": null,
                "created_at": "2024-01-31T12:06:59.000000Z",
                "updated_at": "2024-01-31T12:06:59.000000Z"
            },
            {
                "id": 2,
                "name": "Appstango Account",
                "email": "mantu.s@appstango.com",
                "phone_number": "9606281213",
                "city": "Kolkata",
                "status": "Verified",
                "email_verified_at": null,
                "created_at": "2024-01-31T12:34:12.000000Z",
                "updated_at": "2024-01-31T12:34:12.000000Z"
            },
            {
                "id": 3,
                "name": "Test Admin",
                "email": "testadmin@gmail.com",
                "phone_number": "9606281213",
                "city": "Kolkata",
                "status": "Verified",
                "email_verified_at": null,
                "created_at": "2024-02-19T10:41:43.000000Z",
                "updated_at": "2024-02-19T10:41:43.000000Z"
            },
            {
                "id": 4,
                "name": "Test Admin",
                "email": "test.admin@gmail.com",
                "phone_number": "9606281213",
                "city": "Kolkata",
                "status": "Verified",
                "email_verified_at": null,
                "created_at": "2024-02-23T11:50:30.000000Z",
                "updated_at": "2024-02-23T11:50:30.000000Z"
            },
            {
                "id": 5,
                "name": "Test Account",
                "email": "test.account@gmail.com",
                "phone_number": "9606281213",
                "city": "Kolkata",
                "status": "Verified",
                "email_verified_at": null,
                "created_at": "2024-02-23T11:58:00.000000Z",
                "updated_at": "2024-02-23T11:58:00.000000Z"
            },
            {
                "id": 6,
                "name": "User",
                "email": "user@gmail.com",
                "phone_number": "9606281213",
                "city": "Kolkata",
                "status": "Verified",
                "email_verified_at": null,
                "created_at": "2024-02-28T12:44:50.000000Z",
                "updated_at": "2024-02-28T12:44:50.000000Z"
            }
        ],
        userAccess: [            
            {
              "id": 1,
              "parent_id": 0,
              "name": "Users",
              "sub_menu": [
                {
                  "id": 2,
                  "parent_id": 1,
                  "name": "View",
                  "is_active": "1",
                  "created_at": null,
                  "updated_at": null
                },
                {
                  "id": 3,
                  "parent_id": 1,
                  "name": "Export",
                  "is_active": "1",
                  "created_at": null,
                  "updated_at": null
                },
                {
                  "id": 4,
                  "parent_id": 1,
                  "name": "Import",
                  "is_active": "1",
                  "created_at": null,
                  "updated_at": null
                },
                {
                  "id": 5,
                  "parent_id": 1,
                  "name": "Add & Edit",
                  "is_active": "1",
                  "created_at": null,
                  "updated_at": null
                }
              ]
            },
            {
              "id": 6,
              "parent_id": 0,
              "name": "Access Group",
              "sub_menu": [
                {
                  "id": 7,
                  "parent_id": 6,
                  "name": "View",
                  "is_active": "1",
                  "created_at": null,
                  "updated_at": null
                },
                {
                  "id": 8,
                  "parent_id": 6,
                  "name": "Add & Edit",
                  "is_active": "1",
                  "created_at": null,
                  "updated_at": null
                }
              ]
            },
            {
              "id": 9,
              "parent_id": 0,
              "name": "Coupon",
              "sub_menu": [
                {
                  "id": 10,
                  "parent_id": 9,
                  "name": "View",
                  "is_active": "1",
                  "created_at": null,
                  "updated_at": null
                },
                {
                  "id": 11,
                  "parent_id": 9,
                  "name": "Edit",
                  "is_active": "1",
                  "created_at": null,
                  "updated_at": null
                }
              ]
            }
          ]
    },
    profile: {
        countryList: [
            { country_name: 'US', id: '1' },
            { country_name: 'UK', id: '2' },
            { country_name: 'INDIA', id: '3' },
            { country_name: 'JAPAN', id: '4' }
        ],
        stateList: [
            { state_name: 'AK', id: '1' },
            { state_name: 'CD', id: '2' },
            { state_name: 'BD', id: '3' },
            { state_name: 'OP', id: '4' }
        ],
        genderList: [
            { gender_option_name: 'Male', id: '1' },
            { gender_option_name: 'Female', id: '2' },
            { gender_option_name: 'Other', id: '3' },
        ],
        languageList: [
            { language_name: 'English', id: '1' },
            { language_name: 'Spanish', id: '2' },
            { language_name: 'Bengali', id: '3' },
            { language_name: 'Hindi', id: '4' },
            { language_name: 'German', id: '5' },
            { language_name: 'Portuguese', id: '6' },
            { language_name: 'Bulgarian', id: '7' },
            { language_name: 'Chinese', id: '8' },
        ],
        nationalityList: [
            { nationality_name: 'Afghan', id: '1' },
            { nationality_name: 'Algerian', id: '2' },
            { nationality_name: 'Australian', id: '3' },
            { nationality_name: 'Austrian', id: '4' },
            { nationality_name: 'Bangladeshi', id: '5' },
            { nationality_name: 'Belgian', id: '6' },
            { nationality_name: 'Brazilian', id: '7' },
            { nationality_name: 'Chinese', id: '8' },
            { nationality_name: 'Colombian', id: '9' },
            { nationality_name: 'English', id: '10' },
            { nationality_name: 'French', id: '11' },
            { nationality_name: 'German', id: '12' },
            { nationality_name: 'Icelandic', id: '13' },
            { nationality_name: 'Indian', id: '14' },
            { nationality_name: 'Indonesian', id: '15' },
            { nationality_name: 'Japanese', id: '16' },
            { nationality_name: 'Russian', id: '17' },
        ],
        MsList: [
            { ms_option_name: 'Single', id: '1' },
            { ms_option_name: 'Married', id: '2' },
        ]
    },
    accessGroup: {
        accessGroupList: [
            {
                "id": 1,
                "name": "Admin",
                "menu_ids": "1,2,3,4,5,6,7,8,9,10,11",
                "is_active": "1",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": 2,
                "name": "Sub Admin",
                "menu_ids": "1,2,3,4,5,9,10,11",
                "is_active": "1",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": 3,
                "name": "Regional Manager",
                "menu_ids": "1,2,4,5,9,10,11",
                "is_active": "1",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": 4,
                "name": "Branch Manager",
                "menu_ids": "1,2,4,5",
                "is_active": "1",
                "created_at": null,
                "updated_at": null
            },
            {
                "id": 5,
                "name": "Sales Representative",
                "menu_ids": "1,2,4,5",
                "is_active": "1",
                "created_at": null,
                "updated_at": null
            }
        ],
        permissionList: [
            {
                "main_memu_id": 1,
                "main_memu_name": "User",
                "sub_menu_details": [
                    {
                        "id": 2,
                        "parent_id": 1,
                        "name": "View",
                        "is_active": "1",
                        "created_at": null,
                        "updated_at": null
                    },
                    {
                        "id": 3,
                        "parent_id": 1,
                        "name": "Add & Edit & Delete",
                        "is_active": "1",
                        "created_at": null,
                        "updated_at": null
                    },
                    {
                        "id": 4,
                        "parent_id": 1,
                        "name": "Export",
                        "is_active": "1",
                        "created_at": null,
                        "updated_at": null
                    },
                    {
                        "id": 5,
                        "parent_id": 1,
                        "name": "Import",
                        "is_active": "1",
                        "created_at": null,
                        "updated_at": null
                    },
                ]
            },
            {
                "main_memu_id": 6,
                "main_memu_name": "Access Group",
                "sub_menu_details": [
                    {
                        "id": 7,
                        "parent_id": 6,
                        "name": "View",
                        "is_active": "1",
                        "created_at": null,
                        "updated_at": null
                    },
                    {
                        "id": 8,
                        "parent_id": 6,
                        "name": "Add & Edit",
                        "is_active": "1",
                        "created_at": null,
                        "updated_at": null
                    }
                ]
            },
            {
                "main_memu_id": 9,
                "main_memu_name": "Coupon",
                "sub_menu_details": [
                    {
                        "id": 10,
                        "parent_id": 9,
                        "name": "Add & Edit",
                        "is_active": "1",
                        "created_at": null,
                        "updated_at": null
                    },
                    {
                        "id": 11,
                        "parent_id": 9,
                        "name": "Delete",
                        "is_active": "1",
                        "created_at": null,
                        "updated_at": null
                    }
                ]
            }
        ]
    }
}