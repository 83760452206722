<section class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1>Profile</h1>
            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-4 d-flex">
                <!-- Profile picture card-->
                <div class="card mb-4  w-100">
                    <div class="card-header">Profile Picture</div>
                    <div class="card-body text-center">
                        <!-- Profile picture image-->
                        <div class="profImg">
                            <img class="img-account-profile rounded-circle mb-2 img-fluid" [src]="profileData?.picture || 'assets/img/default-profile.png'" alt="">
                        </div>
                        <!-- Profile picture help block-->
                        <div class="profName">{{profileData?.name || 'NA'}}</div>
                        <div class="small text-muted mb-2 Profdesig">{{profileData?.role || 'NA'}}</div>
                       <!-- <p class="profDescrip mb-4 text-muted">consectetur adipiscing elit sed do eiusmod tempor
                            incididunt ut labore et dolore magna aliqua</p> -->
                            
                        <!-- Profile picture upload button-->
                        <label class="btn btn-primary" for="upload">Upload new image</label>
                        <input type="file" id="upload" class="d-none" (change)="onFileChange($event)">
                        <div class="alert alert-success" *ngIf="successMsgFleUpload!=''">
                            {{successMsgFleUpload}}
                        </div>
                        <div class="alert alert-danger" *ngIf="warningMsgFleUpload!=''">
                            {{warningMsgFleUpload}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-8 d-flex ">
                <!-- Account details card-->
                <div class="card mb-4 w-100">
                    <div class="card-header row align-items-center">
                        <div class="col-8">Profile Details</div>
                        <div title="Edit" class="col-4 d-flex justify-content-end edtProfile">
                            <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#editProfileInfoModal"
							(click)="openProfileEditForm()"><i class="fas fa-pen me-2"></i></a>
                        </div>
                    </div>
                    <div class="card-body profBody">
                        <form>
                            <!-- Form Group (username)-->
                            <div class="row align-items-center">
                                <div class="col-md-6 mb-3">
                                    <label>Name: </label>
                                    <span>{{profileData?.name || 'NA'}} </span>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>Email ID: </label>
                                    <span> {{profileData?.email || 'NA'}} </span>
                                </div>
                            </div>
                           
                            <div class="row align-items-center">
                                <div class="col-md-6 mb-3">
                                    <label>Phone Number:</label>
                                    <span>{{profileData?.mobile_no || 'NA'}} </span>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>DOB:</label>
                                    <span>{{profileData?.dob || 'NA'}} </span>
                                </div>
                            </div>

                            <div class="row align-items-center">
                                <div class="col-md-6 mb-3">
                                    <label>Address:</label>
                                    <span>{{profileData?.address || 'NA'}} </span>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label>City:</label>
                                    <span>{{profileData?.city || 'NA'}} </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</section>

<!-- Start Profile Edit Section -->
<div class="modal fade" id="editProfileInfoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content" *ngIf="showProfileEditForm">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Edit Profile Details</h5>
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form autocomplete="false" name="profileEditForm" method="post" id="profileEditForm" [formGroup]="profileEditForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="name" class="col-form-label"> Name 
                            <span class="required-fields">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="name">
                        <div class="alert alert-danger" *ngIf="profileEditForm.get('name')?.invalid && profileEditForm.get('name')?.errors!=null && (profileEditForm.get('name')?.dirty || profileEditForm.get('name')?.touched)">
                            <div *ngIf="profileEditForm.get('name')?.hasError('required')">
                            This field is required..							
                            </div>
                        </div>        	
                    </div>	
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="email" class="col-form-label">Email 
                            <span class="required-fields">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="email" readonly>
                        <div class="alert alert-danger" *ngIf="profileEditForm.get('email')?.invalid && profileEditForm.get('email')?.errors!=null && (profileEditForm.get('email')?.dirty || profileEditForm.get('email')?.touched)">
                            <div *ngIf="profileEditForm.get('email')?.hasError('required') || profileEditForm.get('email')?.hasError('email')">
                                Please enter valid email id							
                            </div>
                        </div>        	
                    </div>	
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="address" class="col-form-label">Address 
                            <span class="required-fields">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="address">
                        <div class="alert alert-danger" *ngIf="profileEditForm.get('address')?.invalid && profileEditForm.get('address')?.errors!=null && (profileEditForm.get('address')?.dirty || profileEditForm.get('address')?.touched)">
                            <div *ngIf="profileEditForm.get('address')?.hasError('required')">
                            This field is required..							
                            </div>
                        </div>        	
                    </div>	
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="country" class="col-form-label">Country
                            <span class="required-fields">*</span>
                        </label>
                        <select id="country_id" class="form-select form-control" formControlName="country_id">
                            <option value="">select country</option>
                            <option *ngFor="let country of countryList;" [value]="country?.id">
                                {{country?.country_name}}
                            </option>
                        </select>
                        <div class="alert alert-danger"
                        *ngIf="profileEditForm.get('country_id')?.invalid && profileEditForm.get('country_id')?.errors!=null && (profileEditForm.get('country_id')?.dirty || profileEditForm.get('country_id')?.touched)">
                            <div *ngIf="profileEditForm.get('country_id')?.hasError('required')">
                                This field is required.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="state" class="col-form-label">State
                            <span class="required-fields">*</span>
                        </label>
                        <select id="state_id" class="form-select form-control" formControlName="state_id">
                            <option *ngFor="let state of stateList;" [value]="state?.id">
                                {{state?.state_name}}
                            </option>
                        </select>
                        <div class="alert alert-danger"
                        *ngIf="profileEditForm.get('state_id')?.invalid && profileEditForm.get('state_id')?.errors!=null && (profileEditForm.get('state_id')?.dirty || profileEditForm.get('state_id')?.touched)">
                            <div *ngIf="profileEditForm.get('country_id')?.hasError('required')">
                                This field is required.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="city" class="col-form-label">City
                            <span class="required-fields">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="city">
                        <div class="alert alert-danger"
                        *ngIf="profileEditForm.get('city')?.invalid && profileEditForm.get('city')?.errors!=null && (profileEditForm.get('city')?.dirty || profileEditForm.get('city')?.touched)">
                        <div *ngIf="profileEditForm.get('city')?.hasError('required')">
                                This field is required.
                        </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="zip_code" class="col-form-label">Zip Code
                            <span class="required-fields">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="zip_code">
                        <div class="alert alert-danger"
                        *ngIf="profileEditForm.get('zip_code')?.invalid && profileEditForm.get('zip_code')?.errors!=null && (profileEditForm.get('zip_code')?.dirty || profileEditForm.get('zip_code')?.touched)">
                        <div *ngIf="profileEditForm.get('zip_code')?.hasError('required')">
                                This field is required.
                        </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="mobile-no" class="col-form-label">Mobile No. 
                            <span class="required-fields">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="mobile_no">
                        <div class="alert alert-danger" *ngIf="profileEditForm.get('mobile_no')?.invalid && profileEditForm.get('mobile_no')?.errors!=null && (profileEditForm.get('mobile_no')?.dirty || profileEditForm.get('mobile_no')?.touched)">
                            <div *ngIf="profileEditForm.get('mobile_no')?.hasError('required')">
                            This field is required..							
                            </div>
                            <div
                            *ngIf="profileEditForm.get('mobile_no')?.hasError('minlength') || profileEditForm.get('mobile_no')?.hasError('maxlength') || profileEditForm.get('mobile_no')?.hasError('pattern')">
                            this field should be a valid 10 digits number
                        </div>
                        </div>        	
                    </div>	
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="gender" class="col-form-label">Gender
                            <span class="required-fields">*</span>
                        </label>
                        <select id="gender" class="form-select form-control" formControlName="gender">
                            <option *ngFor="let gender of genderList;" [value]="gender?.gender_option_name">
                                {{gender?.gender_option_name}}
                            </option>
                        </select>
                        <div class="alert alert-danger"
                        *ngIf="profileEditForm.get('gender')?.invalid && profileEditForm.get('gender')?.errors!=null && (profileEditForm.get('gender')?.dirty || profileEditForm.get('gender')?.touched)">
                            <div *ngIf="profileEditForm.get('gender')?.hasError('required')">
                                This field is required.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="dob" class="col-form-label">DOB
                            <span class="required-fields">*</span>
                        </label>
                        <input type="text" class="form-control" formControlName="dob"  id="dob" placeholder="Select Date" placement="top" readonly bsDatepicker [bsConfig]="bsConfig">
                        
                        <div class="alert alert-danger"
                        *ngIf="profileEditForm.get('dob')?.invalid && profileEditForm.get('dob')?.errors!=null && (profileEditForm.get('dob')?.dirty || profileEditForm.get('dob')?.touched)">
                            <div *ngIf="profileEditForm.get('dob')?.hasError('required')">
                                This field is required.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="marital_status" class="col-form-label">Marital Status
                            <span class="required-fields">*</span>
                        </label>
                        <select id="marital_status" class="form-select form-control" formControlName="marital_status">
                            <option *ngFor="let ms of MsList;" [value]="ms?.ms_option_name">
                                {{ms?.ms_option_name}}
                            </option>
                        </select>
                        <div class="alert alert-danger"
                        *ngIf="profileEditForm.get('marital_status')?.invalid && profileEditForm.get('marital_status')?.errors!=null && (profileEditForm.get('marital_status')?.dirty || profileEditForm.get('marital_status')?.touched)">
                            <div *ngIf="profileEditForm.get('marital_status')?.hasError('required')">
                                This field is required.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="gender" class="col-form-label">Nationality
                            <span class="required-fields">*</span>
                        </label>
                        <select id="nationality" class="form-select form-control" formControlName="nationality">
                            <option *ngFor="let nationality of nationalityList;" [value]="nationality?.id">
                                {{nationality?.nationality_name}}
                            </option>
                        </select>
                        <div class="alert alert-danger"
                        *ngIf="profileEditForm.get('nationality')?.invalid && profileEditForm.get('nationality')?.errors!=null && (profileEditForm.get('nationality')?.dirty || profileEditForm.get('nationality')?.touched)">
                            <div *ngIf="profileEditForm.get('nationality')?.hasError('required')">
                                This field is required.
                            </div>
                        </div>
                    </div>
                </div>               
            </div>			         
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="submitProfileEditForm()" [disabled]="profileEditForm.invalid">Update</button>
    </div>
    </div>
  </div>
</div>