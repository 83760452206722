import { Component, OnInit, Input } from '@angular/core';

declare var google: any;

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  private options;
  private data:any;
  private chart;

  @Input() idName;
  @Input() dataValue;
  @Input() type;

  constructor() { }

  ngOnInit() {
    google.charts.load('current', {
      'packages': ['corechart']
    });
    google.charts.setOnLoadCallback(() => this.drawAreaChart());
  }

  /**
   * drawAreaChart
   */
  public drawAreaChart() {
    if (this.dataValue!= undefined) {
      const rawData = [...this.dataValue];

      if (this.type == 'Company Performance') {
        rawData.unshift(['Year', 'Sales', 'Expenses']);
        this.data = new google.visualization.arrayToDataTable(rawData);
      } else if (this.type == 'BoxOfficeEarnings') {
        this.data = new google.visualization.DataTable();
        this.data.addColumn('number', 'Day');
        this.data.addColumn('number', 'Guardians of the Galaxy');
        this.data.addColumn('number', 'The Avengers');
        this.data.addColumn('number', 'Transformers: Age of Extinction');
        this.data.addRows(rawData);
      }
    }

    if (this.type == 'Company Performance') {
      this.options = {
        title: 'Company Performance',
        curveType: 'function',
        hAxis: {
          title: "year",
          titleTextStyle: { color: '#333' }
        },
        vAxis: { minValue: 0 },
        chartArea: { top:10, width: '70%' },
        legend: { position: 'bottom', alignment: 'center' },
        tooltip:{textStyle: {color: '#000'}, showColorCode: true},
        colors: ["#0072A7","#5BCBFF"]
      };
    } else if (this.type == 'BoxOfficeEarnings') {
      this.options = {
        chart: {
          title: 'Box Office Earnings in First Two Weeks of Opening',
          subtitle: 'in millions of dollars (USD)'
        },
        chartArea: { top:10, width: '70%' },
      };
    }

    this.chart = new google.visualization.LineChart(document.getElementById(this.idName));
    this.chart.draw(this.data, this.options);
  }

}
